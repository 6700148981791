import {
  IonButton,
  IonContent,
  IonIcon,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import CryptoJS from "react-native-crypto-js";
import { personAddSharp, personCircle } from "ionicons/icons";
import "./Connect.css";

import { useDispatch, useSelector } from "react-redux";
// import { TabsAction } from "../../ReduxStore/Actions/index";
import { ConnectAction, IndustryAction } from "../../ReduxStore/Actions/index";

import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services";
import { withRouter } from "react-router";
import { Toast } from "../../Utils/Toast";
const Connect = (props) => {
  let dispatch = useDispatch();
  const [state, setState] = useState({});

  const [userData, setUserData] = useState(null);
  const ConnectData = useSelector((state) => state.connectReducer.searchData);
  const searchText = useSelector((state) => state.connectReducer.searchText);
  console.log("search Text", searchText);
  const addConnectionStatus = useSelector(
    (state) => state.connectReducer.status
  );
  const addConnectionMessage = useSelector(
    (state) => state.connectReducer.message
  );
  const loading = useSelector((state) => state.commonReducer.loading);
  const industryData = useSelector(
    (state) => state.IndustryReducer.industryData
  );

  useEffect(() => {
    (async () => {
      let userDataa = localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA);
      let user;
      if (userDataa) {
        user = JSON.parse(userDataa);
        setUserData(JSON.parse(userDataa));
      }
      await dispatch(IndustryAction.GetIndustryAction());
    })();
  }, []);
  const handleChange = (name, e) => {
    let { value } = e.detail;

    setState((prevState) => ({ ...prevState, [name]: value }));
    console.log("state", state);
  };
  const onViewProfile = async (e, data) => {
    e.preventDefault();
    let selectedUserId = data.id;

    const userIdKey = await CryptoJS.AES.encrypt(
      selectedUserId.toString(),
      APPLICATION_CONSTANTS.REMEMBER_USER_SECRETE_KEY
    ).toString();
    var dataString = userIdKey
      .replace(/\+/g, "p1L2u3S")
      .replace(/\//g, "s1L2a3S4h")
      .replace(/=/g, "e1Q2u3A4l");

    await props.history.push(`/view-profile/${dataString}`);
  };

  const onAddConnect = async (e, data) => {
    e.preventDefault();
    // console.log("starrrrrrrrr",{
    //   connection_id: data.id,
    //   user_id: userData.id
    // })
    await dispatch(
      ConnectAction.addConnection({
        connection_id: data.id,
        user_id: userData.id,
      })
    );
    // await dispatch({type:ACTION_TYPE.CLEAR_SEARCH_LIST} );
  };

  return (
    <IonContent>
      <main className="col-12 col-md-12 main connect">
        {addConnectionStatus === true ? (
          <Toast
            isOpen={addConnectionStatus}
            message={addConnectionMessage}
            isSuccess={addConnectionStatus}
            isClearSuccess={true}
            isClearError={true}
            actionType={ACTION_TYPE.CLEAR_SEARCH_LIST}
          />
        ) : null}

        <section className="core-rail">
          <div className="share-details-card">
            <h2 style={{ textAlign: "center" }}>
              <IonIcon icon={personAddSharp}></IonIcon> Connect
            </h2>
          </div>
          {/* <div style={{ margin: "30px 0px" }} className="share-details-card"></div> */}
          {/* <IonSearchbar style={{ margin: "15px 0px" }} className="searchBarConnect"></IonSearchbar> */}

          <div className="row">
            {/* <div className="col-12 col-sm-12 col-md-4">
              <div
                className="share-details-card explore-card"
                style={{ padding: 10 }}
              >
                <h3 style={{ fontWeight: 600 }}>Explore</h3>
                <h4>Select an Industry</h4>
                <IonSelect
                  interface="popover"
                  // interfaceOptions={options}
                  placeholder="Select One"
                  name="industry"
                  value={selected}
                  onIonChange={(e) => setSelected(e.detail.value)}
                  // value={state?.industry}
                  // onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  {industryData &&
                    industryData.data &&
                    industryData.data.map((data) => {
                      return (
                        <div>
                          <IonSelectOption value={data.industry_type}>
                            {data.industry_type}
                          </IonSelectOption>
                        </div>
                      );
                    })}
                </IonSelect>
                <ul></ul>
                <h4>Select a Status</h4>
                <IonSelect
                  interface="popover"
                  // interfaceOptions={options}
                  placeholder="Select One"
                  name="status"
                  value={status}
                  onIonChange={(e) => handleChange(e.target.value)}
                  // value={state?.industry}
                  // onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  <div>
                    <IonSelectOption value={1}>Student</IonSelectOption>
                  </div>
                  <div>
                    <IonSelectOption value={2}>Professional</IonSelectOption>
                  </div>
                </IonSelect>
                <ul></ul>
                {entreprenuer ? (
                  <div>
                    <h4>Is an Entreprenuer?</h4>
                    <IonSelect
                      interface="popover"
                      placeholder="Select One"
                      name="status"
                      value={entreprenuerOption}
                      onIonChange={(e) => setEntreprenuerOption(e.target.value)}
                      style={{ border: "1px solid #c7c7c7" }}
                    >
                      <div>
                        <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                      </div>
                      <div>
                        <IonSelectOption value={"No"}>No</IonSelectOption>
                      </div>
                    </IonSelect>
                    <ul></ul>
                  </div>
                ) : null}
                <IonButton
                  className="button-green"
                  onClick={async () => {
                    await dispatch(
                      ConnectAction.getSearchConnections(
                        searchText,
                        selected,
                        status,
                        entreprenuerOption
                      )
                    );
                  }}
                >
                  Apply
                </IonButton>
              </div>
            </div>
     */}
            <div
              style={{ margin: "0px auto" }}
              className="col-12 col-sm-12 col-md-12"
            >
              {searchText ? (
                <div className="share-details-card">
                  <h2 style={{ textAlign: "center" }}>Filter By</h2>
                  <ul className="filterby">
                    <li>
                      <IonSearchbar
                        placeholder="Search Name"
                        name="name"
                        onIonChange={(e) => {
                          handleChange("name", e);
                        }}
                      />
                    </li>
                    <li>
                      <IonSearchbar
                        placeholder="Search Title"
                        name="title"
                        onIonChange={(e) => {
                          handleChange("title", e);
                        }}
                      />
                    </li>

                    <li>
                      {" "}
                      <IonSelect
                        interface="popover"
                        placeholder="Select Industry"
                        name="industry"
                        value={state?.industry}
                        onIonChange={(e) => handleChange("industry", e)}
                      >
                        {industryData &&
                          industryData.data &&
                          industryData.data.map((data) => {
                            return (
                              <div>
                                <IonSelectOption value={data.industry_type}>
                                  {data.industry_type}
                                </IonSelectOption>
                              </div>
                            );
                          })}
                      </IonSelect>
                    </li>
                    <li>
                      <IonSelect
                        interface="popover"
                        // interfaceOptions={options}
                        placeholder="Select Status"
                        name="status"
                        value={state?.status}
                        onIonChange={(e) => handleChange("status", e)}
                      >
                        <div>
                          <IonSelectOption value={1}>Student</IonSelectOption>
                        </div>
                        <div>
                          <IonSelectOption value={2}>
                            Professional
                          </IonSelectOption>
                        </div>
                        <div>
                          <IonSelectOption value={3}>
                            Entrepreneur
                          </IonSelectOption>
                        </div>
                      </IonSelect>{" "}
                    </li>
                    <li>
                      <IonButton
                        className="button-green"
                        onClick={async () => {
                          await dispatch(
                            ConnectAction.getSearchConnections(
                              searchText,
                              state
                            )
                          );
                        }}
                      >
                        Apply
                      </IonButton>
                    </li>
                  </ul>
                </div>
              ) : null}
              <div className="share-details-card">
                <ul className="connectionsAll">
                  {ConnectData &&
                  ConnectData.data &&
                  ConnectData.data.length ? (
                    ConnectData.data.map((data, i) => {
                      console.log("daaatttttaaaaa", data);
                      return (
                        <li className="reusableli" key={i}>
                          <div className="entry_list">
                            <div
                              className="entry_list_items"
                              style={{ display: "block" }}
                            >
                              <div
                                className="col-md-4 col-sm-12"
                                style={{ float: "left" }}
                              >
                                <div className="entity_list__image">
                                  <div
                                    style={{
                                      // display: "flex",
                                      // alignItems: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img src={data.profile_image}></img>
                                  </div>
                                </div>
                                <div className="entity_list_content">
                                  <div className="entity_list_content_info">
                                    <div>
                                      <h5>{data.full_name} </h5>
                                      <p>{data.industry} </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-md-8 col-sm-12"
                                style={{ float: "right" }}
                              >
                                <div className="entity_list_actions">
                                  <IonButton
                                    className="button-green ion-green-button"
                                    onClick={(e) => {
                                      onViewProfile(e, data);
                                    }}
                                  >
                                    <IonIcon icon={personCircle} />
                                    View Profile
                                  </IonButton>
                                  {data.isConnected === 0 ? (
                                    <IonButton
                                      className="button-green ion-green-button"
                                      onClick={(e) => onAddConnect(e, data)}
                                    >
                                      <IonIcon icon={personAddSharp} />
                                      Connect
                                      {/* {(() => {
                                      if (loading) {
                                        return (
                                          <IonSpinner
                                            name="crescent"
                                            className="spinner-loader"
                                          />
                                        );
                                      }
                                    })()} */}
                                    </IonButton>
                                  ) : (
                                    <h6
                                      className="ion-green-button"
                                      style={{
                                        color: "green",
                                        padding: "0px 5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Connected
                                    </h6>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <h2 style={{ padding: 10, textAlign: "center" }}>
                      Try searching connections by name or profession
                    </h2>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </IonContent>
  );
};

export default withRouter(Connect);
