import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon,
} from "@ionic/react";
import { IonItem, IonCheckbox } from "@ionic/react";
import React, { useState } from "react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { ValidateEmail } from "./Validation";
import { withRouter, Link } from "react-router-dom";
import { ROUTES, APPLICATION_CONSTANTS } from "../../Services/index";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";

const Login = (props) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);
  const [errorData, setError] = React.useState();
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const loading = useSelector((state) => state.commonReducer.loading);
  const authData = useSelector((state) => state.authReducer);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmail(value );
  };

  async function onSubmitForm(event) {
    event.preventDefault();
    const result = await ValidateEmail(email);
    console.log("RESUL", result);
    if (result?.isFormValid) {
      setError({});

     await dispatch(AuthActions.forgetPassword({email}));
      
    } else {
      setError(result?.error);
    }
  }

  return (
    <IonContent>
      <div className="login">
        <div className="login-inner">
        {(errorStatus === false || errorStatus === true) && errorMessage ? (
            <Toast
              isOpen={errorStatus === false ? true : false}
              message={errorMessage}
              isSuccess={errorStatus}
              isClearSuccess={true}
              isClearError={true}
            />
          ) : authData?.status ? (
            <Toast
              isOpen={authData?.status}
              message={authData?.message}
              isSuccess={authData?.status}
              isClearSuccess={true}
              isClearError={true}
              duration={3000}
            />
          ) : null}
          <div className="login-head">
            <h2>Forget Password</h2>
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <IonLabel>Email Address<sup>*</sup></IonLabel>
                <IonInput
                  type="text"
                  value={email ? email : ""}
                  name="email"
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange(event);
                  }}
                ></IonInput>
                <p> {errorData?.email && errorData.email[0]}</p>
              </div>

              <div className="button-login">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Send Email
                  {(() => {
                    if (loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>

              {/* <div class="haveaccount">
                <p>
                  Already have an account?{" "}
                  <Link to={ROUTES.Login}> Log In</Link>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default withRouter(Login);
