import React from "react";
import {
	IonContent,
	IonInput,
	IonButton,
	IonLabel,
	IonImg,
	IonSpinner,
	IonIcon,
} from "@ionic/react";
import LoginString from "../Login/LoginString";
const styleWebformIframeEmbed = {
	width: "100px",
	minWidth: "100%",
	height: "100%",
};

const DSF = () => {
	const token = localStorage.getItem("token");
	return (
		<IonContent>
			<iframe
				id="discussion-forum-frame"
				src={`https://discussionforum.studentproconnect.com/?token=${token}`}
				title="MY WEBFORM TITLE"
				frameBorder="0"
				allowFullScreen
				// style={styleWebformIframeEmbed}
				// scrolling={true}
				style={{ width: "100%", height: "100%" }}
			></iframe>
		</IonContent>
	);
};

export default DSF;
