import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
import { APPLICATION_CONSTANTS } from "../../Services/Strings/index";

export const LikePost = (postId) => async (dispatch) => {
	let userData = JSON.parse(
		localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
	);
	const res = await Api.post(ENDPOINTS.LIKEPOST, {
		userId: userData.id,
		userName: userData.full_name,
		postId: postId,
	});
	console.log("RRRRR", res?.data?.data);
	try {
		await dispatch({
			type: ACTION_TYPE.LIKEPOST,
			payload: res.data,
		});
	} catch (error) {}
};
