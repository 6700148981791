// export const BASE_URL = "http://localhost:5000";


export const BASE_URL = "https://as731accf8.execute-api.us-east-1.amazonaws.com/development";
//   LIVE

// https://as731accf8.execute-api.us-east-1.amazonaws.com/development  

export const ENDPOINTS = {
  LOGIN: "/api/v1/userAuth/login",
  SIGNUP: "/api/v1/userAuth/signup",
  VERIFY_EMAIL: "/api/v1/userAuth/verifyCode",
  FORGET_PASSWORD: "/api/v1/userAuth/forgotPassword",
  RESET_PASSWORD: "/api/v1/userAuth/resetPassword",

  MATCH_TOKEN: "/api/v1/userAuth/matchToken",
  USER_POSTS: "api/v1/post/getMyPosts/",
  DELETE_POSTS: "api/v1/post/disable/",
  SEARCH_CONNECTIONS: "api/v1/user/search",

  //Profile APIs
  GET_PROFILE_DETAILS: "/api/v1/profile/getProfileDetails/",
  UPDATE_PROFILE_DETAILS: "/api/v1/profile/profileDetails/",
  ADMIN_PROFILE_DETAILS: "/api/v1/profile/adminDetails/",
  RETAIN_ADMIN_DETAILS: "/api/v1/profile/retainDetails",
  CHANGE_COVER_IMAGE: "/api/v1/profile/uploadCoverImage/",
  CHANGE_PROFILE_IMAGE: "/api/v1/profile/uploadProfileImage/",
  UPLOAD_POST: "api/v1/post/upload",
  GET_NEWSFEED: "api/v1/post/newsfeed",
  SHAREPOST: "/api/v1/post/share/",
  LIKEPOST: "/api/v1/post/likePost/",
  LIKEPOST: "/api/v1/post/likePost/",
  DISLIKEPOST: "/api/v1/post/disLikePost/",
  POSTCOMMENT: "/api/v1/post/addComment/",
  // GET_NEWSFEED: "http://localhost:5000/api/v1/post/newsfeed/",
  // SHAREPOST: "http://localhost:5000/api/v1/post/share/",

  GET_MY_CONNECTION: "/api/v1/user/myConnection/",
  GET_INDUSTRY: "api/v1/user/industry",
  ADD_CONNECTION: "api/v1/user/connect",

  //Get Dynamic Pages
  GET_PAGES_CONTENT: "api/v1/admin/pages/slug/",

  CONTACT_US: "/api/v1/user/contact-us",
};

export const ROUTES = {
  //   <<-- Website Routes -->>
  Home: "/",
  Admin: "/admin",
  About: "/about",
  ContactUs: "/contact-us",
  Student: "/student",
  Entrepreneurs: "/entrepreneurs",
  Podcast: "/podcast",
  Industry_Professional: "/industry-professional",
  DYNAMIC_PAGE: "/pages/:pageId",
  TERMS_AND_CONDITION: "/pages/terms-and-condition",
  PRIVATE_POLICY: "/pages/privacy-policy",
  NEWS_FEED: "/feed",

  // <<-- Auth Routes -->>

  SignUp: "/sign-up",
  ThankYouPage: "/sign-up-successful",
  Login: "/login",
  ForgetPassword: "/forgot-password",
  EmailVerify: "/verify",
  ResetPassword: "/reset-password",

  //  <<-- APP Routes -->>

  Profile: "/profile",
  ViewProfile: "/view-profile/:userId",
  Connect: "/connect",
  Chat: "/chat",
  DiscussionForum: "discussion-forum",
};
