import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { commonReducer } from "./commonReducer";
import { TabsReducer } from "./TabsReducer";
import { ProfileReducer } from "./profileReducer";
import { connectReducer } from "./connectReducer";
import { UserPostsReducer } from "./userPostsReducer";
import { IndustryReducer } from "./industryReducer";
import { pageReducer } from "./pageReducer";
import { newsfeedReducer } from "./newsfeedReducer";
import { likesReducer } from "./likeReducer";
import { dislikeReducer } from "./disLikeReducer";
import { postCommentReducer } from "./postCommentReducer";

const appReducer = combineReducers({
	authReducer,
	commonReducer,
	TabsReducer,
	ProfileReducer,
	UserPostsReducer,
	connectReducer,
	IndustryReducer,
	pageReducer,
	newsfeedReducer,
	likesReducer,
	dislikeReducer,
	postCommentReducer,
});
export default appReducer;
