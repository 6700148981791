import { ACTION_TYPE ,APPLICATION_CONSTANTS} from "../../Services/index";
export const initialState = {
    userPostsData:[],
  };
  
  export const UserPostsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE.USER_POSTS:
            return {
              ...state,
              userPostsData: action.payload,
            };
      default:
        return state;
    }
  };
  