import React from "react";
import LoginString from "../Login/LoginString";
import firebase from "../../Services/FireBase";
import "./Chat.css";
import ReactLoading from "react-loading";

// import images from '../../ProjectImages/ProjectImages';
import ChatBox from "./components/ChatBox/ChatBox";
import WelcomeBoard from "./components/Welcome/Welcome";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonRow,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  
} from "@ionic/react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { ConnectAction } from "../../ReduxStore/Actions/index";
import { getMyConnections } from "../../ReduxStore/Actions/connectAction";
import { BackButtonEvent } from '@ionic/core';
import { Plugins } from '@capacitor/core';



import { searchOutline } from "ionicons/icons";
const { App } = Plugins;
class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isOpenDialogConfirmLogout: false,
      currentPeerUser: null,
      displayedContacts: [],
      displayedContactswithNotification: [],
      hideNav:""
    };
    this.currentUserDocumentId = localStorage.getItem(
      LoginString.FirebaseDocumentId
    );
    this.currentUserId = localStorage.getItem(LoginString.ID);
    this.currentUserPhoto = localStorage.getItem(LoginString.PhotoURL);

    this.currentUserName = localStorage.getItem(LoginString.Name);
    this.currentUserMessages = [];

    this.searchUsers = [];
    this.myConnectionList = [];
    this.displayedContacts = [];
    this.currentUserMessages = [];
    this.notificationMessagesErase = [];
    this.getListUsers = this.getListUsers.bind(this);
    this.renderListUser = this.renderListUser.bind(this);
    this.notificationErase = this.notificationErase.bind(this);
  }
  logout = () => {
    firebase.auth().signOut();
    this.props.history.push("/");
    localStorage.clear();
  };
  onProfileClick = () => {
    this.props.history.push("/profile");
  };
  async componentDidMount() {
    if(window.innerWidth<=767)
    {
      if(document.getElementById("chats").style.display==="block")
      {
        document.addEventListener('ionBackButton', (ev) => {
          document.getElementById("chats").style.display="none";
          document.getElementById("userList").style.display="block";
        });
      }
    }

    let notificationMessages = [];
    // window.addEventListener("resize", this.resize.bind(this));
    // this.resize();
    // if (this.currentUserDocumentId) {
    // this.props.ConnectAction.getMyConnections(this.currentUserId)
    console.log("this currentUserdocuid", this.currentUserDocumentId);
   await this.props.getMyConnections(this.currentUserId);
    firebase
      .firestore()
      .collection("users")
      .doc(this.currentUserDocumentId)
      .get()
      .then((doc) => {
        // console.log("docccccccc", doc.data().messages);
        doc &&
          doc.data() &&
          doc.data().messages &&
          doc.data().messages.map((item) => {
            this.currentUserMessages.push({
              notificationId: item.notificationId,
              number: item.number,
            });
          });
        this.setState({
          displayedContactswithNotification: this.currentUserMessages,
        });
      });
    this.getListUsers();
    //    }
  }
  resize() {
    console.log("window size",window.innerWidth)
    if(window.innerWidth<=767)
    {
  console.log("inside")
  document.getElementById("chats").style.display="block";
  document.getElementById("userList").style.display="none";
  
    }
    // this.setState({hideNav: window.innerWidth <= 760});
}


  getListUsers = async () => {
    const result = await firebase.firestore().collection("users").get();
    if (result.docs.length > 0) {
      let listUsers = [];
      listUsers = [...result.docs];
      listUsers.forEach((item, index) => {
        this.searchUsers.push({
          key: index,
          documentkey: item.id,
          id: item.data().id,
          name: item.data().name,
          messages: item.data().messages,
          URL: item.data().URL,
          description: item.data().description,
        });
      });
      this.setState({ isLoading: false });
    }

    this.renderListUser();
  };

  getClassnameforUserandNotification = (itemId) => {
    let number = 0;
    let className = "";
    let check = false;
    if (
      this.state.currentPeerUser &&
      this.state.currentPeerUser.id === itemId
    ) {
      className = "viewWrapItemFocused";
    } else {
      this.state.displayedContactswithNotification.forEach((item) => {
        if (item.notificationId.length > 0) {
          if (item.notificationId === itemId) {
            check = true;
            number = item.number;
          }
        }
      });
      if (check === true) {
        className = "viewWrapItemNotification";
      } else {
        className = "viewWrapItem";
      }
    }
    return className;
  };
  notificationErase = (itemId) => {
    this.state.displayedContactswithNotification.forEach((el) => {
      if (el.notificationId.length > 0) {
        if (el.notificationId != itemId) {
          this.notificationMessagesErase.push({
            notificationId: el.notificationId,
            number: el.number,
          });
        }
      }
    });
    this.updaterenderlist();
  };
  updaterenderlist = () => {
    firebase
      .firestore()
      .collection("users")
      .doc(this.currentUserDocumentId)
      .update({ messages: this.notificationMessagesErase });
    this.setState({
      displayedContactswithNotification: this.notificationMessagesErase,
    });
  };

  renderListUser = () => {
    // console.log("props insoode listttt", this.currentUserId);

    if (
      this.props &&
      this.props.myConnection &&
      this.props.myConnection.length > 0
    ) {
      this.props.myConnection.map((data) => {
        this.myConnectionList.push(data.id);
      });
    }
    if (this.searchUsers.length > 0) {
      let viewListUser = [];
      // console.log("props of chat", this.props.myConnection);

      let classname = "";
      this.searchUsers.map((item) => {
        // console.log("list", item);
        if (this.myConnectionList.includes(item.id)) {
          if (item.id != this.currentUserId) {
            classname = this.getClassnameforUserandNotification(item.id);
            viewListUser.push(
              <IonItem
                id={item.key}
                className={classname}
                onClick={() => {
                  this.notificationErase(item.id);
                  this.setState({
                    currentPeerUser: item,
                    displayedContactswithNotification: this
                      .notificationMessagesErase,
                  });
                  this.resize();
                  // document.getElementById(item.key).style.backgroundColor =
                  //   "#fff";
                  // if (document.getElementById(item.key)) {
                  //   document.getElementById(item.key).style.color = "#fff";
                  // }
                }}
              >
                {/* <div className="viewWrapContentItem"> */}
                <IonLabel>{`${item.name}`}</IonLabel>
                {/* <span className="textItem"></span> */}
                {/* </div> */}
                {classname === "viewWrapItemNotification" ? (
                  <div className="notificationpragraph">
                    <p id={item.key} className="newmessages">
                      New messages
                    </p>
                  </div>
                ) : null}
              </IonItem>
            );
          }
        }
      });
      this.setState({
        displayedContacts: viewListUser,
      });
    } else {
      // console.log("No user is present");
    }
  };
  searchHandler = (event) => {
    let searchQuery = event.target.value.toLowerCase(),
      displayedContacts = this.searchUsers.filter((el) => {
        let SearchValue = el.name.toLowerCase();
        return SearchValue.indexOf(searchQuery) !== -1;
      });
    this.displayedContacts = displayedContacts;
    this.displaySearchedContacts();
  };
  displaySearchedContacts = () => {
    if (this.searchUsers.length > 0) {
      let viewListUser = [];
      let classname = "";
      this.displayedContacts.map((item) => {
        if (item.id != this.currentUserId) {
          classname = this.getClassnameforUserandNotification(item.id);
          viewListUser.push(
            <button
              id={item.key}
              className={classname}
              onClick={() => {
                this.notificationErase(item.id);
                this.setState({
                  currentPeerUser: item,
                  displayedContactswithNotification: this
                    .notificationMessagesErase,
                });
                document.getElementById(item.key).style.backgroundColor =
                  "#fff";
                if (document.getElementById(item.key)) {
                  document.getElementById(item.key).style.color = "#fff";
                }
              }}
              style={{ padding: 20 }}
            >
              {/* <img
                        className="viewAvatarItem"
                        src={item.URL}
                        alt=""
                        // placeholder={images.emptyphoto}
                    /> */}

              <div className="viewWrapContentItem">
                <span className="textItem">{` ${item.name}`}</span>
              </div>
              {classname === "viewWrapItemNotification" ? (
                <div className="notificationpragraph">
                  <p id={item.key} className="newmessages">
                    New messages
                  </p>
                </div>
              ) : null}
            </button>
          );
        }
      });
      this.setState({
        displayedContacts: viewListUser,
      });
    } else {
      // console.log("No user is present");
    }
  };
  render() {
    return (
      <IonContent>
        <IonGrid className="root">
          <IonRow className="body">
            <IonCol id="userList" sizeXs="12" className="viewListUser" sizeLg="4" sizeXl="4" sizeMd="4">
              <div col-sm-12 className="rootsearchbar">
                <div className="input-container">
                  <input
                    class="input-field"
                    type="text"
                    onChange={this.searchHandler}
                    placeholder="User Search"
                  />
                </div>
              </div>
              <IonList>{this.state.displayedContacts}</IonList>
            </IonCol>
            <IonCol id="chats" className="viewBoard" sizeXs="12"  sizeLg="8" sizeXl="8"  sizeMd="8">
              {this.state.currentPeerUser ? (
                <ChatBox
                  currentPeerUser={this.state.currentPeerUser}
                  showToast={this.props.showToast}
                />
              ) : (
                <WelcomeBoard
                  currentUserName={this.currentUserName}
                  currentUserPhoto={this.currentUserPhoto}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myConnection: state.connectReducer.myConnection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMyConnections }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
