import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon,
} from "@ionic/react";
import { IonItem, IonCheckbox } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { ValidateSignUpForm } from "./Validation";
import { withRouter, Link } from "react-router-dom";
import { ROUTES, APPLICATION_CONSTANTS } from "../../Services/index";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { AuthActions } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";
import firebase from "../../Services/FireBase";

const ResetPassword = (props) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);
  const [state, setState] = React.useState();
  const [errorData, setError] = React.useState();
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const authData = useSelector((state) => state.authReducer);
  const loading = useSelector((state) => state.commonReducer.loading);

  useEffect(() => {
    const resetKey = queryString.parse(props.location.search).accessKey;
    setState((prevState) => ({ ...prevState, resetKey: resetKey }));
    console.log("firebase.auth().currentUser;", firebase.auth().currentUser);
    // firebase.passwordReset('piyushadaniyanov@gmail.com')
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  async function onSubmitForm(event) {
    event.preventDefault();
    const result = await ValidateSignUpForm(state);
    // console.log("RESUL", result);
    if (result?.isFormValid) {
      setError({});
      await dispatch(AuthActions.resetPassword(state));

      //  var user = firebase.auth().currentUser;
    } else {
      setError(result?.error);
    }
  }

  return (
    <IonContent>
      <div className="login">
        <div className="login-inner">
          {(errorStatus === false || errorStatus === true) && errorMessage ? (
            <Toast
              isOpen={errorStatus === false ? true : false}
              message={errorMessage}
              isSuccess={errorStatus}
              isClearSuccess={true}
              isClearError={true}
            />
          ) : authData?.status ? (
            <Toast
              isOpen={authData?.status}
              message={authData?.message}
              isSuccess={authData?.status}
              isClearSuccess={true}
              isClearError={true}
              duration={3000}
            />
          ) : null}
          <div className="login-head">
            <h2>Reset Password</h2>
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <IonLabel>
                  Password<sup>*</sup>
                </IonLabel>
                <IonInput
                  type="text"
                  value={state?.password ? state?.password : ""}
                  name="password"
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange(event);
                  }}
                ></IonInput>
                <p> {errorData?.password && errorData.password[0]}</p>
              </div>

              <div className="form-group">
                <IonLabel>
                  Confirm Password<sup>*</sup>
                </IonLabel>
                <IonInput
                  type="text"
                  value={state?.confirmPassword ? state?.confirmPassword : ""}
                  name="confirmPassword"
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange(event);
                  }}
                ></IonInput>
                <p>
                  {" "}
                  {errorData?.confirmPassword && errorData.confirmPassword[0]}
                </p>
              </div>

              <div className="button-login">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Reset Password
                  {(() => {
                    if (loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>

              {/* <div class="haveaccount">
                <p>
                  Already have an account?{" "}
                  <Link to={ROUTES.Login}> Log In</Link>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default withRouter(ResetPassword);
