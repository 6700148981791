import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
import { APPLICATION_CONSTANTS } from "../../Services/Strings/index";

export const postComment =
	(data, comment, profileImage) => async (dispatch) => {
		let userData = JSON.parse(
			localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
		);
		console.log("PROIMA", data);
		const res = await Api.post(ENDPOINTS.POSTCOMMENT, {
			userId: userData.id,
			userName: userData.full_name,
			postId: data.id,
			comment: comment,
			profile_image: profileImage,
		});
		console.log("RRRRR", res?.data?.data);
		try {
			await dispatch({
				type: ACTION_TYPE.POSTCOMMENT,
				payload: res.data,
			});
		} catch (error) {}
	};
