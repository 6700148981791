import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { CoverImageProfile, FeedProfile } from "./Components/index";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabsAction, ProfileAction } from "../../ReduxStore/Actions/index";
import { APPLICATION_CONSTANTS } from "../../Services";
import CryptoJS from "react-native-crypto-js";
const ViewUserProfile = (props) => {
	const dispatch = useDispatch();

	const TabsData = useSelector((state) => state.TabsReducer.TabsData);
	const authData = useSelector((state) => state.authReducer.authData);
	console.log("AUTHTHTHT", authData);
	const profileDetail = useSelector(
		(state) => state.ProfileReducer.userProfileDetail
	);

	let userId = props?.match?.params?.userId;
	useEffect(() => {
		(async () => {
			userId = await userId
				.replace(/p1L2u3S/g, "+")
				.replace(/s1L2a3S4h/g, "/")
				.replace(/e1Q2u3A4l/g, "=");

				
			userId = CryptoJS.AES.decrypt(
				userId,
				APPLICATION_CONSTANTS.REMEMBER_USER_SECRETE_KEY
			);

			userId = userId.toString(CryptoJS.enc.Utf8);
			console.log("dataString userid",userId)
			await dispatch(ProfileAction.getUserDetails({ id: userId }));

			await dispatch(
				
				TabsAction.GetTabsAction(APPLICATION_CONSTANTS.ALL_POST_TAB)
			);
		})();
	}, [userId]);

	return (
		<IonContent>
			<main className="col-12 col-md-12 main">
				<section className="core-rail profile">
					<CoverImageProfile
						authData={profileDetail}
						profileDetail={profileDetail}
						isOwner={false}
					/>
					<FeedProfile
						TabsId={TabsData}
						profileDetail={profileDetail}
						isOwner={false}
					/>
				</section>
			</main>
		</IonContent>
	);
};

export default withRouter(ViewUserProfile);
