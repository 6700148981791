import { IonContent } from "@ionic/react";
import React from "react";
import "./Professionals.css";
import Footer from "../../components/Footer/Footer";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../Services";
const Professionals = () => {
	return (
		<IonContent>
			<section className="ProfessionalsTop">
				<div className="table">
					<div className="table-cell">
						{/* <img
                className="logo"
                src={require("../../images/ProfessionalsImages/wl.png")}
              /> */}
						<h1 className="headingMain">Industry Professionals</h1>
					</div>
				</div>
			</section>

			<section className="ProfessionalsSection2 container">
				<div className="col-md-6 col-sm-12 ">
					<p className="TopPara">
						<b>
							StudentProCONNECT<sup>®</sup>
						</b>
						&nbsp; gives you the opportunity to share your knowledge and experiences in
						the industry with the next up and coming candidates in the talent
						pool.{" "}
					</p>
					<ol className="gradient-list">
						<li>
							Share your experiences and insights with your fellow industry
							professionals and students
						</li>
						<li>Network & establish connections</li>
						<li>Establish relationships with potential job applicants</li>
						<li>Great way to give back and make an impact</li>
					</ol>
				</div>
				<div className="col-md-6 col-sm-12">
					<img
						alt="image"
						style={{ maxWidth: "100%" }}
						src={require("../../images/ProfessionalsImages/Professionals.png")}
					/>
				</div>
			</section>
			{localStorage.getItem("token") ? null : (
							<div className="loginSignup" style={{textAlign:'center'}}>
								<span style={{ margin: "2px 10px" }}>
									<Link to={ROUTES.Login}>
										<Button
											style={{
												borderRadius: "2rem",
												padding: "5px 35px",
												background: "#ffffff",
												borderColor: "#092C05",
												color: "black",
                        width:'250px',
                        marginRight:'20px'
											}}
										>
											Login{" "}
										</Button>
									</Link>{" "}
									<Link to={ROUTES.SignUp}>
										<Button
											style={{
												borderRadius: "2rem",
												padding: "5px 35px",
												background: "#ffffff",
												borderColor: "#092C05",
												color: "black",
                        width:'250px'
											}}
										>
											Sign Up{" "}
										</Button>
									</Link>{" "}
								</span>
							</div>
						)}
			<Footer></Footer>
		</IonContent>
	);
};

export default Professionals;
