import React, { Component, useEffect } from "react";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { menuController } from "@ionic/core";
import { PagesAction } from "../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";

// import Logo from "../images/logo-blue.png";
import "./Dynamic.css";
import { ACTION_TYPE } from "../../Services";

const SlugPage = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.pageReducer.data);

  useEffect(() => {
    return () => {
      dispatch({ type: ACTION_TYPE.CLEAR_DYNAMIC_PAGE_CONTENT });
    };
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(PagesAction.getPageDetails(props?.match?.params?.pageId));
    })();
  }, [props?.match?.params?.pageId]);

  return (
    <IonContent>
      <main className="mainContainer">
        <section className="home-product-section pb-5 mt-4">
          <div className="container">
            <div className="editor-page">
              <div className="editor-page-logo">
                {/* <img src={Logo} alt="" /> */}
              </div>

              <h1 className="editor-page-title">
                <b>{data?.title}</b>
              </h1>

              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.content ? data?.content : `Loading...`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </IonContent>
  );
};

export default React.memo(SlugPage);

// class SlugPage extends Component {
//   constructor(props) {
//     super(props);
//     menuController.enable(false);
//     this.state = {
//       content: "",
//       title: "",
//     };
//   }

//   async componentDidMount() {
//     menuController.enable(false);
//     this.fetchPageContent(this.props.match.params.pageSlug);
//   }

//   fetchPageContent = async (pageSlug) => {
//     const result = await this.props.getPageContent(pageSlug);
//     if (result?.data?.data) {
//       this.setState({
//         content: result.data.data.content,
//         title: result.data.data.title,
//       });
//     }
//   };

//   componentWillReceiveProps(nextProps) {
//     if (nextProps.match.params.pageSlug !== this.props.match.params.pageSlug) {
//       this.fetchPageContent(nextProps.match.params.pageSlug);
//     }
//   }

//   render() {
//     return (
//       <IonContent>
//         <main className="mainContainer">
//           <section className="home-product-section pb-5 mt-4">
//             <div className="container">
//               <div className="editor-page">
//                 <div className="editor-page-logo">
//                   {/* <img src={Logo} alt="" /> */}
//                 </div>

//                 <h1 className="editor-page-title">
//                   <b>{this.state.title}</b>
//                 </h1>

//                 <div>
//                   <div
//                     dangerouslySetInnerHTML={{
//                       __html: this.state.content
//                         ? this.state.content
//                         : `Loading...`,
//                     }}
//                   ></div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </main>
//       </IonContent>
//     );
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ getPageContent }, dispatch);
// };

// export default connect(null, mapDispatchToProps)(SlugPage);
