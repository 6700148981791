import React from "react";
import { Card } from "react-bootstrap";
import ReactLoading from "react-loading";
// import 'react-toastify/dist/ReactToastify.css';
import firebase from "../../../../Services/FireBase";
// import images from '../../ProjectImages/ProjectImages';
import moment from "moment";
import "./ChatBox.css";
import LoginString from "../../../Login/LoginString";
import {
  IonGrid,
  IonIcon,
  IonRow,
  IonCol,
  IonCardContent,
  IonHeader,
  IonBackButton,
  IonButton,
} from "@ionic/react";
import { sendOutline } from "ionicons/icons";
// import 'bootstrap/dist/css/bootstrap.min.css';
export default class ChatBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isShowStiker: false,
      inputValue: "",
    };

    this.currentUserName = localStorage.getItem(LoginString.Name);
    // this.currentUserId = parseInt(localStorage.getItem(LoginString.ID))
    this.currentUserId = localStorage.getItem(LoginString.ID);
    this.currentUserPhoto = localStorage.getItem(LoginString.PhotoURL);
    this.currentUserDocumentId = localStorage.getItem(
      LoginString.FirebaseDocumentId
    );
    this.stateChanged = localStorage.getItem(LoginString.UPLOAD_CHANGED);
    this.currentPeerUser = this.props.currentPeerUser;
    this.groupChatId = null;
    this.listMessage = [];
    this.currentPeerUserMessages = [];
    this.removeListener = null;
    this.currentPhotoFile = null;
    console.log("docccc key", this.groupChatId);
    firebase
      .firestore()
      .collection("users")
      .doc(this.currentPeerUser.documentkey)
      .get()
      .then((docRef) => {
        console.log("docccc message", docRef.data());
        this.currentPeerUserMessages = docRef.data().messages;
      });
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.currentPeerUser) {
      this.currentPeerUser = newProps.currentPeerUser;
      this.getListHistory();
    }
  }
  componentDidMount() {
    this.getListHistory();
  }
  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
    }
  }
  getListHistory = () => {
    if (this.removeListener) {
      this.removeListener();
    }
    this.listMessage.length = 0;
    this.setState({ isLoading: true });
    if (
      this.hashString(this.currentUserId) <=
      this.hashString(this.currentPeerUser.id.toString())
    ) {
      console.log(
        "if if if",
        this.currentUserId,
        this.currentPeerUser.id,
        typeof this.currentUserId,
        typeof this.currentPeerUser.id
      );
      this.groupChatId = `${
        this.currentUserId
      }-${this.currentPeerUser.id.toString()}`;
    } else {
      console.log(
        "else else",
        this.currentUserId,
        this.currentPeerUser.id,
        typeof this.currentUserId,
        typeof this.currentPeerUser.id
      );

      this.groupChatId = `${this.currentPeerUser.id.toString()}-${
        this.currentUserId
      }`;
    }
    console.log(
      "else else",
      this.currentUserId,
      this.currentPeerUser.id,
      typeof this.currentUserId,
      typeof this.currentPeerUser.id
    );
    this.removeListener = firebase
      .firestore()
      .collection("Messages")
      .doc(this.groupChatId)
      .collection(this.groupChatId)
      .onSnapshot(
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            console.log(
              "changeeeeeeeeeeeeeee",
              change.doc.data(),
              this.groupChatId,
              change.type,
              LoginString.DOC
            );
            if (change.type === LoginString.DOC) {
              // console.log("changeeeeeeeeeeeeeee in if",change.doc.data(), this.groupChatId,change.type, LoginString.DOC)

              this.listMessage.push(change.doc.data());
              console.log("listtt meassage", this.listMessage);
            }
          });
          this.setState({ isLoading: false });
        },
        (err) => {
          console.log("ERR L", err);
          // this.props.showToast(0, err.toString());
        }
      );
  };
  onSendMessage = (content, type) => {
    let notificationMessages = [];
    if (this.state.isShowStiker && type === 2) {
      this.setState({ isShowStiker: false });
    }
    if (content.trim() === "") {
      return;
    }
    const timestamp = moment().valueOf().toString();
    console.log(
      "from ",
      this.currentUserId + "to  ",
      this.currentPeerUser.id,
      " 000chatt id",
      this.groupChatId
    );
    const itemMessage = {
      idFrom: this.currentUserId,
      idTo: this.currentPeerUser.id.toString(),
      timestamp: timestamp,
      content: content.trim(),
      type: type,
    };
    firebase
      .firestore()
      .collection("Messages")
      .doc(this.groupChatId)
      .collection(this.groupChatId)
      .doc(timestamp)
      .set(itemMessage)
      .then(() => {
        this.setState({ inputValue: "" });
      });
    this.currentPeerUserMessages.map((item) => {
      if (item.notificationId != this.currentUserId) {
        notificationMessages.push({
          notificationId: item.notificationId,
          number: item.number,
        });
      }
    });
    firebase
      .firestore()
      .collection("users")
      .doc(this.currentPeerUser.documentkey)
      .update({
        messages: notificationMessages,
      })
      .then((data) => {})
      .catch((err) => {
        this.props.showToast(0, err.toString());
      });
  };
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({});
    }
  };
  handleBack = () => {
    console.log("changeddddddd");
    document.getElementById("chats").style.display = "none";
    document.getElementById("userList").style.display = "block";
  };
  onKeyboardPress = (event) => {
    if (event.key === "Enter") {
      this.onSendMessage(this.state.inputValue, 0);
    }
  };
  openListSticker = () => {
    this.setState({ isShowSticker: !this.state.isShowSticker });
  };
  render() {
    return (
      <div style={{ width: "100%", paddingTop: 100 }}>
        <IonGrid className="viewChatBoard">
          <IonRow className="backbutton">
            <IonCol>
              <IonButton className="backk" onClick={this.handleBack}>Back</IonButton>
            </IonCol>
          </IonRow>
          {/* <IonHeader><IonBackButton></IonBackButton></IonHeader> */}
          <IonRow>
            <IonCol className="headerChatBoard">
              <span className="textHeaderChatBoard">
                <p style={{ fontSize: "20px" }}> {this.currentPeerUser.name}</p>
              </span>
              <div className="aboutme">
                <span>
                  <p>{this.currentPeerUser.description}</p>
                </span>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="allchats">
            <IonCol className="viewListContentChat">
              {this.renderListMessage()}
              <div
                style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="inputFooter"
          >
            {/* {this.state.isShowSticker ? this.renderStickers() : null} */}
            <IonCol className="viewBottom">
              <input
                className="viewInput"
                placeholder="Type a message"
                value={this.state.inputValue}
                onChange={(event) => {
                  this.setState({ inputValue: event.target.value });
                }}
                onKeyPress={this.onKeyboardPress}
              />
              <IonIcon
                className="icSend"
                icon={sendOutline}
                // //src={images.send}
                // alt="icon send"
                onClick={() => this.onSendMessage(this.state.inputValue, 0)}
              />
            </IonCol>
          </IonRow>
          {this.state.isLoading ? (
            <div className="viewLoading">
              <ReactLoading
                type={"spin"}
                color={"#203152"}
                height={"3%"}
                width={"3%"}
              />
            </div>
          ) : null}
          {/* </IonRow> */}
        </IonGrid>
      </div>
    );
  }
  onChoosePhoto = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ isLoading: true });
      this.currentPhotoFile = event.target.files[0];
      const prefixFiletype = event.target.files[0].type.toString();
      if (prefixFiletype.indexOf("image/") === 0) {
        this.uploadPhoto();
      } else {
        this.setState({ isLoading: false });
        this.props.showToast(0, "This file is not an image");
      }
    } else {
      this.setState({ isLoading: false });
    }
  };
  uploadPhoto = () => {
    if (this.currentPhotoFile) {
      const timestamp = moment().valueOf().toString();

      const uploadTask = firebase
        .storage()
        .ref()
        .child(timestamp)
        .put(this.currentPhotoFile);

      uploadTask.on(
        LoginString.UPLOAD_CHANGED,
        null,
        (err) => {
          this.setState({ isLoading: false });
          this.props.showToast(0, err.message);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({ isLoading: false });
            this.onSendMessage(downloadURL, 1);
          });
        }
      );
    } else {
      this.setState({ isLoading: false });
      this.props.showToast(0, "File is null");
    }
  };
  renderListMessage = () => {
    if (this.listMessage.length > 0) {
      let viewListMessage = [];
      this.listMessage.forEach((item, index) => {
        // this.currentUserId=parseInt()
        console.log(
          "idfrom",
          typeof item.idFrom,
          item.idFrom,
          "current",
          typeof this.currentUserId,
          this.currentUserId
        );
        if (item.idFrom === this.currentUserId) {
          if (item.type === 0) {
            viewListMessage.push(
              <div className="viewItemRight" key={item.timestamp}>
                <span className="textContentItem">{item.content}</span>
              </div>
            );
          } else if (item.type === 1) {
            viewListMessage.push(
              <div className="viewItemRight2" key={item.timestamp}>
                <img
                  className="imgItemRight"
                  //src={item.content}
                  alt="content message"
                />
              </div>
            );
          } else {
            viewListMessage.push(
              <div className="viewItemRight3" key={item.timestamp}>
                <img
                  className="imgItemRight"
                  //src={this.getGifImage(item.content)}
                  alt="content message"
                />
              </div>
            );
          }
        } else {
          if (item.type === 0) {
            viewListMessage.push(
              <div className="viewWrapItemLeft" key={item.timestamp}>
                <div className="viewWrapItemLeft3">
                  {this.isLastMessageLeft(index) ? (
                    <div></div>
                  ) : (
                    // <img
                    //     //src={this.currentPeerUser.URL}
                    //     alt="avatar"
                    //     className="peerAvatarLeft"
                    // />
                    <div className="viewPaddingLeft" />
                  )}
                  <div className="viewItemLeft">
                    <span className="textContentItem">{item.content}</span>
                  </div>
                </div>
                {this.isLastMessageLeft(index) ? (
                  <span className="textTimeLeft">
                    <div className="time">
                      {moment(Number(item.timestamp)).format("ll")}
                    </div>
                  </span>
                ) : null}
              </div>
            );
          } else if (item.type === 1) {
            viewListMessage.push(
              <div className="viewWrapItemLeft2" key={item.timestamp}>
                <div className="viewWrapItemLeft3">
                  {this.isLastMessageLeft(index) ? (
                    <div></div>
                  ) : (
                    // <img
                    //     //src={this.currentPeerUser.URL}
                    //     alt="avatar"
                    //     className="peerAvatarLeft"
                    // />
                    <div className="viewPaddingLeft" />
                  )}
                  <div className="viewItemLeft2">
                    <img
                      className="imgItemLeft"
                      //src={item.content}
                      alt="content message"
                    />
                  </div>
                </div>
                {this.isLastMessageLeft(index) ? (
                  <span className="textTimeLeft">
                    <div className="time">
                      {moment(Number(item.timestamp)).format("ll")}
                    </div>
                  </span>
                ) : null}
              </div>
            );
          } else {
            viewListMessage.push(
              <div className="viewWrapItemLeft2" key={item.timestamp}>
                <div className="viewWrapItemLeft3">
                  {this.isLastMessageLeft(index) ? (
                    <img
                      //src={this.currentPeerUser.URL}
                      alt="avatar"
                      className="peerAvatarLeft"
                    />
                  ) : (
                    <div className="viewPaddingLeft" />
                  )}
                  <div className="viewItemLeft3" key={item.timestamp}>
                    <img
                      className="imgItemLeft"
                      //src={this.getGifImage(item.content)}
                      alt="content message"
                    />
                  </div>
                </div>
                {this.isLastMessageLeft(index) ? (
                  <span className="textTimeLeft">
                    <div className="time">
                      <div className="timesetup">
                        {moment(Number(item.timestamp)).format("ll")}
                      </div>
                    </div>
                  </span>
                ) : null}
              </div>
            );
          }
        }
      });
      return viewListMessage;
    } else {
      return (
        <div className="viewWrapSayHi">
          <span className="textSayHi">Say hi to new friend</span>
          {/* <img
                        className="imgWaveHand"
                        //src={images.wave_hand}
                        alt="wave hand"
                    /> */}
        </div>
      );
    }
  };
  renderStickers = () => {
    return (
      <div className="viewStickers">
        <img
          className="imgSticker"
          //src={images.lego1}
          alt="sticker"
          onClick={() => {
            this.onSendMessage("lego1", 2);
          }}
        />
        <img
          className="imgSticker"
          //src={images.lego2}
          alt="sticker"
          onClick={() => this.onSendMessage("lego2", 2)}
        />
        <img
          className="imgSticker"
          //src={images.lego3}
          alt="sticker"
          onClick={() => this.onSendMessage("lego3", 2)}
        />
        <img
          className="imgSticker"
          //src={images.lego4}
          alt="sticker"
          onClick={() => this.onSendMessage("lego4", 2)}
        />
        <img
          className="imgSticker"
          //src={images.lego5}
          alt="sticker"
          onClick={() => this.onSendMessage("lego5", 2)}
        />
        <img
          className="imgSticker"
          //src={images.lego6}
          alt="sticker"
          onClick={() => this.onSendMessage("lego6", 2)}
        />

        <img
          className="imgSticker"
          //src={images.mimi1}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi1", 2)}
        />
        <img
          className="imgSticker"
          //src={images.mimi2}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi2", 2)}
        />

        <img
          className="imgSticker"
          //src={images.mimi4}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi4", 2)}
        />
        <img
          className="imgSticker"
          //src={images.mimi5}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi5", 2)}
        />
        <img
          className="imgSticker"
          //src={images.mimi6}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi6", 2)}
        />
        <img
          className="imgSticker"
          //src={images.mimi7}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi7", 2)}
        />
        <img
          className="imgSticker"
          //src={images.mimi8}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi8", 2)}
        />
        <img
          className="imgSticker"
          //src={images.mimi9}
          alt="sticker"
          onClick={() => this.onSendMessage("mimi9", 2)}
        />
      </div>
    );
  };
  getGifImage = (value) => {
    // switch (value) {
    //     case 'lego1':
    //         return images.lego1
    //     case 'lego2':
    //         return images.lego2
    //     case 'lego3':
    //         return images.lego3
    //     case 'lego4':
    //         return images.lego4
    //     case 'lego5':
    //         return images.lego5
    //     case 'lego6':
    //         return images.lego6
    //     case 'mimi1':
    //         return images.mimi1
    //     case 'mimi2':
    //         return images.mimi2
    //     case 'mimi4':
    //         return images.mimi4
    //     case 'mimi5':
    //         return images.mimi5
    //     case 'mimi6':
    //         return images.mimi6
    //     case 'mimi7':
    //         return images.mimi7
    //     case 'mimi8':
    //         return images.mimi8
    //     case 'mimi9':
    //         return images.mimi9
    //     default:
    //         return null
    // }
  };
  hashString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += Math.pow(str.charCodeAt(i) * 31, str.length - i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };
  isLastMessageLeft(index) {
    if (
      (index + 1 < this.listMessage.length &&
        this.listMessage[index + 1].idFrom === this.currentUserId) ||
      index === this.listMessage.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  isLastMessageRight(index) {
    if (
      (index + 1 < this.listMessage.length &&
        this.listMessage[index + 1].idFrom !== this.currentUserId) ||
      index === this.listMessage.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  }
}
