import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon,
  IonPage,
  IonModal,
} from "@ionic/react";
import {
  cameraOutline,
  appsOutline,
  brushOutline,
  closeSharp,
  peopleCircleOutline,
  peopleOutline,
  personAdd,
  personAddOutline,
} from "ionicons/icons";
import "../../Profile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  TabsAction,
  ProfileAction,
  UserPostsAction,
} from "../../../../ReduxStore/Actions/index";
import {
  APPLICATION_CONSTANTS,
  APPLICATION_IMAGE_CONSTANTS,
  ROUTES,
} from "../../../../Services";
import { Plugins, CameraResultType } from "@capacitor/core";
import { withRouter } from "react-router";
const { Camera } = Plugins;

const CoverImageProfile = (props) => {
//   console.log("props CoverImageProfile", props);

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [postPreview, setPostPreview] = useState(false);
  const loading = useSelector((state) => state.commonReducer.loading);

  const [imageType, setImageType] = useState(null);
  const [photo, setPhoto] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [coverImagePreview, setCoverImagePreview] = useState();
  const [profileImagePreview, setProfileImagePreview] = useState();
  const TabsData = useSelector((state) => state.TabsReducer.TabsData);
  const [caption, setCaption] = useState("");
  // const [props?.isOwner, setIsOwnerProfile] = useState(true);

  useEffect(() => {
    if (imageType === 1) {
      if (!selectedFile) {
        setCoverImagePreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFile);
      setCoverImagePreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else if (imageType === 2) {
      if (!selectedFile) {
        setProfileImagePreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFile);
      setProfileImagePreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else if (imageType == 3) {
      if (!selectedFile) {
        setPostPreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFile);
      setPostPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    setCaption(e.target.value);
  };

  const onSelectCoverImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  const setPreviewModelFalse = () => {
    setShowModal(false);
    setSelectedFile();
    setCoverImagePreview();
    setProfileImagePreview();
    setShowPreviewImage(false);
    setImageType(null);
  };
  const uploadImage = async () => {
    let d = localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA);
    let id = JSON.parse(d).id;

    const fileToUpload = selectedFile;
    console.log("fileToUpload", fileToUpload, imageType);
    const data = new FormData();
    data.append("image", fileToUpload);

    if (imageType === 1) {
      await dispatch(ProfileAction.updateCoverImage(data, id));
      await dispatch(ProfileAction.getUserDetails({ id: id }));
      setPreviewModelFalse();
    } else if (imageType === 2) {
      await dispatch(ProfileAction.updateProfileImage(data, id));
      await dispatch(ProfileAction.getUserDetails({ id: id }));
      setPreviewModelFalse();
    } else if (imageType === 3) {
      data.append("description", caption);
      data.append("status", 1);
      data.append("user_id", id);
      await dispatch(ProfileAction.uploadPost(data));
      await dispatch(UserPostsAction.GetUserPosts(id));
      setPreviewModelFalse();
    }

    // setShowPreviewImage(false);
  };

  // useEffect(() => {
  // 	console.log("COVERIMAGE");
  // }, []);

  return (
    <section className="top-card-layout">
      <div className="cover-img onload lazy-loaded">
        <div className="editimg-box">
          <div className="editimg-inner">
            <img
              className="cover-img__image"
              src={
                APPLICATION_IMAGE_CONSTANTS.COVER_IMAGE
              }
              src={
                props?.profileDetail?.cover_image
                  ? props?.profileDetail?.cover_image
                  : APPLICATION_IMAGE_CONSTANTS.COVER_IMAGE
              }
            ></img>
          </div>
          {props?.isOwner === true ? (
            <a className="editimg-box-btn">
              <IonIcon
                onClick={() => {
                  setShowPreviewImage(true);
                  setImageType(1);
                }}
                icon={cameraOutline}
              ></IonIcon>
            </a>
          ) : null}
        </div>
      </div>

      <div className="top-card-layout__card">
        <div className="editimg-profilebox">
          <div className="editimg-profilebox-inner">
            {/* <img
              className="top-card-layout__entity-image artdeco-entity-image--square-8 artdeco-entity-image"
              src={
                props?.profileDetail?.profile_image
                  ? props?.profileDetail?.profile_image
                  : APPLICATION_IMAGE_CONSTANTS.PROFILE_IMAGE
              }
            /> */}
            <img
              style={{ backgroundColor: "black" }}
              src={
                props?.profileDetail?.profile_image
                  ? props?.profileDetail?.profile_image
                  : APPLICATION_IMAGE_CONSTANTS.PROFILE_IMAGE
              }
            />
            {props?.isOwner ? (
              <a className="editimg-box-btn-profile">
                <IonIcon
                  onClick={() => {
                    setShowPreviewImage(true);
                    setImageType(2);
                  }}
                  icon={cameraOutline}
                ></IonIcon>
              </a>
            ) : null}
          </div>
        </div>

        <div className="top-card-layout__entity-info-container">
          <div className="top-card-layout__entity-info">
            <h1 className="top-card-layout__title">
              {props?.profileDetail ? props?.profileDetail?.full_name : props?.authData?.full_name}
            </h1>
            <p className="top-card-layout__headline">
              @{props?.authData?.username}
            </p>
          </div>
          {props?.isOwner ? (
            <IonButton
              className="button-green"
              onClick={() => {
                setShowModal(true);
                setImageType(3);
              }}
            >
              Add Post
            </IonButton>
          ) : null}

          {/* Add post modal*/}
          <IonModal
            className="modal-wrapper text-editor-modal"
            isOpen={showModal}
          >
            <IonContent>
              <div className="modal-header">
                <h2>Create a post</h2>
                <IonIcon
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  icon={closeSharp}
                />
              </div>

              <div className="modal-body">
                <div className="text-editor-img">
                  {postPreview ? <IonImg src={postPreview}></IonImg> : null}
                </div>

                <IonButton className="button-green mb-10">
                  <input
                    style={{
                      width: "133%",
                      padding: "10px 26px",
                    }}
                    className="file-custom"
                    accept="image/*"
                    type="file"
                    name="cover_image"
                    onChange={onSelectCoverImage}
                  ></input>
                  Choose Image
                </IonButton>

                <div className="modal-input-group">
                  <textarea
                    className="modal-input"
                    placeholder="What is on your mind? "
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>

              <div className="modal-footer">
                <div>
                  <IonButton
                    className="button-green"
                    onClick={() => {
                      uploadImage();
                    }}
                  >
                    Post{" "}
                    {(() => {
                      if (loading) {
                        return (
                          <IonSpinner
                            name="crescent"
                            className="spinner-loader"
                          />
                        );
                      }
                    })()}
                  </IonButton>
                </div>
              </div>
            </IonContent>
          </IonModal>

          {/* Profile/Cover modal*/}
          <IonModal
            className="modal-wrapper addimg-modal"
            isOpen={showPreviewImage}
          >
            <IonContent>
              <div className="modal-header">
                <h2>Upload your picture</h2>
                <IonIcon
                  className="close"
                  onClick={() => {
                    setPreviewModelFalse(false);
                  }}
                  icon={closeSharp}
                />
              </div>
              <div className="modal-body">
                <div className="imageadd-inner">
                  <IonImg
                    //style={{ width: "100%" }}
                    src={
                      imageType === 1
                        ? coverImagePreview
                        : imageType === 2
                        ? profileImagePreview
                        : null
                    }
                  ></IonImg>
                </div>
              </div>

              <div className="modal-footer">
                <div className="modal-footer-btns">
                  {coverImagePreview || profileImagePreview ? (
                    <div>
                      <IonButton className="button-green" onClick={uploadImage}>
                        {" "}
                        {(() => {
                          if (loading) {
                            return (
                              <IonSpinner
                                name="crescent"
                                className="spinner-loader"
                              />
                            );
                          }
                        })()}
                        Upload Image
                      </IonButton>
                      <IonButton
                        className="button-grey"
                        onClick={() => {
                          setPreviewModelFalse(false);
                        }}
                      >
                        Cancel
                      </IonButton>
                    </div>
                  ) : (
                    <IonButton className="button-green">
                      <input
                        style={{
                          width: "133%",
                          padding: "10px 26px",
                        }}
                        className="file-custom"
                        accept="image/*"
                        type="file"
                        name="cover_image"
                        onChange={onSelectCoverImage}
                      ></input>
                      Choose Image
                      {(() => {
                        if (loading) {
                          return (
                            <IonSpinner
                              name="crescent"
                              className="spinner-loader"
                            />
                          );
                        }
                      })()}
                    </IonButton>
                  )}
                </div>
              </div>
            </IonContent>
          </IonModal>
        </div>
      </div>
      <div className="numberOfpost">
        <ul className="postNav">
          <li
            className={TabsData === 1 ? "active" : null}
            onClick={() => {
              dispatch(
                TabsAction.GetTabsAction(APPLICATION_CONSTANTS.ALL_POST_TAB)
              );
            }}
          >
            <IonIcon icon={appsOutline}></IonIcon>Posts
          </li>
          {props?.isOwner ? (
            <li
              className={TabsData === 2 ? "active" : null}
              onClick={() => {
                dispatch(
                  TabsAction.GetTabsAction(
                    APPLICATION_CONSTANTS.CONNECTIONS_TAB
                  )
                );
              }}
            >
              <IonIcon icon={peopleOutline}></IonIcon> Connections{" "}
            </li>
          ) : null}

          {/* <li>
            <IonIcon icon={brushOutline}></IonIcon> Interests
          </li> */}
        </ul>
      </div>
    </section>
  );
};
export default withRouter(CoverImageProfile);
