import { IonContent } from "@ionic/react";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../Services";
import Footer from "../../components/Footer/Footer";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import "./Home.css";
ReactGA.initialize("UA-188032881-2");

const Home = () => {
  const history = useHistory();

  const podcast = () => {
    history.push(ROUTES.Podcast);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <IonContent>
      <section className="HomeTop">
        <div className="table">
          <div className="table-cell">
            <img
              className="logo"
              alt="logo"
              src={require("../../images/HomeImages/wl.png")}
            />
            <h1 className="headingMain">
              Students, Professionals & <br />
              Entrepreneurs Connect
            </h1>
            {localStorage.getItem("token") ? null : (
              <div className="loginSignup">
                <span style={{ margin: "2px 10px" }}>
                  <Link to={ROUTES.Login}>
                    <Button
                      style={{
                        borderRadius: "2rem",
                        padding: "5px 35px",
                        background: "#ffffff",
                        borderColor: "#092C05",
                        color: "black",
                      }}
                    >
                      Login{" "}
                    </Button>
                  </Link>{" "}
                  <Link to={ROUTES.SignUp}>
                    <Button
                      style={{
                        borderRadius: "2rem",
                        padding: "5px 35px",
                        background: "#ffffff",
                        borderColor: "#092C05",
                        color: "black",
                      }}
                    >
                      Sign Up{" "}
                    </Button>
                  </Link>{" "}
                </span>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="HomeSection2 ">
        <div className="container">
          <div className="col-md-7 col-sm-12 col">
            <img
              className="abtlogo"
              alt="logo"
              src={require("../../images/HomeImages/wl.png")}
            ></img>
            <h2 className="heading">
              StudentProCONNECT<sup>®</sup>{" "}
            </h2>
            <p className="abtP">
              A networking site where students, professionals and entrepreneurs
              can connect, network and most of all share insight and
              experiences.
            </p>
            <Button
              style={{
                borderRadius: "2rem",
                padding: "5px 35px",
                background: "#296863",
                borderColor: "#092C05",
                color: "#E9F4F3",
              }}
              onClick={podcast}
            >
              Podcast
            </Button>
            {/* <button className="theme">View More</button> */}
          </div>
          <div className="col-md-5 col-sm-12 col">
            <img
              alt="logo"
              className="abtImg"
              src={require("../../images/HomeImages/Centreimage.png")}
            />
          </div>
        </div>
      </section>
      <section className="HomeSection3 ">
        <div className="container">
          {/* <div className="col-md-5 col col-sm-12">
						<img
							alt="image"
							style={{ maxWidth: "90%", marginLeft: 30 }}
							src={require("../../images/HomeImages/Bottomimage.png")}
						/>
					</div> */}
          <div className="col-md-12 col col-sm-12">
            <h2 className="heading">What Do We Offer</h2>
            <ol className="gradient-list">
              <li>
                A platform where students can obtain career advice and learn
                from working professionals in various fields.
              </li>
              <li>
                A platform where professionals can share their challenges,
                advice and helpful resources with fellow professionals in their
                field.
              </li>

              <li>
                A platform where entrepreneurs can exchange ideas, insight and
                experiences with fellow entrepreneurs in their respective
                industry.{" "}
              </li>
              <li>
                A platform where students can search for opportunities to
                network and gain experience in their interested fields.
              </li>
              <li>
                A platform where professionals and entrepreneurs can find an
                eager group of prospective interns/employees ready to gain
                experience in various industries.
              </li>
            </ol>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </IonContent>
  );
};

export default withRouter(Home);
