import { ACTION_TYPE ,APPLICATION_CONSTANTS} from "../../Services/index";
export const initialState = {
  message: "",
  status: null,
  myConnection:[],
  searchData:[],
  searchText:""
};

export const connectReducer = (state = initialState, action) => {
  switch (action.type) {
    case  ACTION_TYPE.GET_MY_CONNECTION:
      return {
        ...state,
        myConnection:  action.payload.data.data,
        message: action.payload.data.message,
        status: action.payload.data.status
      };
      case ACTION_TYPE.SEARCH_CONNECTIONS:
        return {
          ...state,
          searchData: action.payload,
        };
        case ACTION_TYPE.SEARCH_TEXT:
          return {
            ...state,
            searchText: action.payload,
          };
          case ACTION_TYPE.ADD_CONNECTION:
            return {
              ...state,
              message: action.payload.data?.message,
              status: action.payload.data?.status,
            };

            case ACTION_TYPE.CLEAR_SEARCH_LIST:
            return {
              message: "",
              status: null,
              myConnection:[],
              searchData:[],
              searchText:""
            }
    default:
      return state;
  }
};
