import { IonContent } from "@ionic/react";
import React from "react";
import "./Podcast.css";
import Footer from "../../components/Footer/Footer";

const Podcast = () => {
  return (
    <IonContent>
      <section className="ProfessionalsTop">
        <div className="table">
          <div className="table-cell">
            <h1 className="headingMain">PodCast</h1>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center">
            <a
              href={
                localStorage.getItem("token")
                  ? "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy81ZTgxYzgzNC9wb2RjYXN0L3Jzcw=="
                  : "/login"
              }
            >
              <ion-card class="podCard">
                <img src={require("../../images/CommonImages/google.png")} />
                <ion-card-header>
                  <ion-card-title>Google Podcast</ion-card-title>
                </ion-card-header>
              </ion-card>
            </a>
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <a
              href={
                localStorage.getItem("token")
                  ? "https://open.spotify.com/show/6oWMsgWp5ys0x3OUuBbTBL"
                  : "/login"
              }
            >
              <ion-card class="podCard">
                <img src={require("../../images/CommonImages/spotify.png")} />
                <ion-card-header>
                  <ion-card-title>Spotify Podcast</ion-card-title>
                </ion-card-header>
              </ion-card>
            </a>
          </div>
          <div className="col-md-4 d-flex pb-0 justify-content-center">
            <a
              href={
                localStorage.getItem("token")
                  ? "https://podcasts.apple.com/us/podcast/the-real-deal-with-studentproconnect/id1578035643"
                  : "/login"
              }
            >
              <ion-card class="podCard">
                <img src={require("../../images/CommonImages/apple.png")} />
                <ion-card-header>
                  <ion-card-title>Apple Podcast</ion-card-title>
                </ion-card-header>
              </ion-card>
            </a>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </IonContent>
  );
};

export default Podcast;
