function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
function isNumber(str) {
  var reg = /^\d+$/;
  return reg.test(str);
}

export const ValidateSignUpForm = (formData) => {
  const {
    fullName,
    email,
    role,
    password,
    confirmPassword,
    is_entreprenuer,
    industry,
    title,
  } = formData || {};
  let error = {
    fullName: [],
    email: [],
    password: [],
    confirmPassword: [],
    role: [],
    industry: [],
    title: [],
    is_entreprenuer: [],
  };
  let isFormValid = true;
  console.log("pp", isNumber(role), parseInt(role) != 1, typeof role);
  if (isBlank(fullName) === true) {
    isFormValid = false;
    error.fullName = ["This field is required."];
  } else if (fullName?.trim().length > 0 && fullName?.trim().length > 50) {
    isFormValid = false;
    error.fullName = ["Name cannot be greater than 50."];
  }
  if (isBlank(industry) === true) {
    isFormValid = false;
    error.industry = ["This field is required."];
  }
  if (isBlank(title) === true) {
    isFormValid = false;
    error.title = ["This field is required."];
  }
  if (isBlank(email) === true) {
    isFormValid = false;
    error.email = ["This field is required."];
  } else if (
    email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = ["Email must be a valid email address."];
  }
  if (!isNumber(role)) {
    isFormValid = false;
    error.role = ["This field is required."];
  }
  if (isBlank(is_entreprenuer) === true) {
    isFormValid = false;
    error.is_entreprenuer = ["This field is required."];
  }
  if (isBlank(password) === true) {
    isFormValid = false;
    error.password = ["This field is required."];
  } else if (
    password?.trim().length > 0 &&
    (password?.trim().length < 6 || password?.trim().length > 15)
  ) {
    isFormValid = false;
    error.password = ["Password length must be between 6 to 15 characters."];
  }
  if (isBlank(confirmPassword) === true) {
    isFormValid = false;
    error.confirmPassword = ["This field is required."];
  } else if (
    confirmPassword?.trim().length > 0 &&
    (confirmPassword?.trim().length < 6 || confirmPassword?.trim().length > 15)
  ) {
    isFormValid = false;
    error.confirmPassword = [
      "Confirm Password length must be between 6 to 15 characters.",
    ];
  } else if (
    confirmPassword?.trim().length > 0 &&
    confirmPassword?.trim().length > 0 &&
    password?.trim() !== confirmPassword?.trim()
  ) {
    isFormValid = false;
    error.confirmPassword = ["Passwords Don’t Match!"];
  }

  return { isFormValid, error };
};
