import { ACTION_TYPE } from "../../Services/index";

export const GetTabsAction = (id) => async (dispatch) => {
  try {
    await dispatch({
      type: ACTION_TYPE.TABS,
      payload: id,
    });
    // dispatch({ type: "LOADING_COMPLETED" });
    // return res.data;
  } catch (error) {
    // dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};



