//import React from 'react';
import React, { useState } from "react";
import {
	IonButtons,
	IonContent,
	IonHeader,
	IonToolbar,
	IonGrid,
	IonRow,
	IonSearchbar,
	IonCol,
	IonImg,
	IonIcon,
	IonButton,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import "./AuthHeader.css";
import { useDispatch, useSelector } from "react-redux";

import {
	menuOutline,
	notificationsOutline,
	notifications,
	information,
	appsOutline,
	mailOutline,
} from "ionicons/icons";
import { Link, useLocation } from "react-router-dom";

import {
	TabsAction,
	ProfileAction,
	ConnectAction,
} from "../../ReduxStore/Actions/index";

import { Redirect, withRouter, useHistory } from "react-router-dom";
import { ACTION_TYPE, ROUTES } from "../../Services";
var clearTimeoutHolder = null;
const AuthHeader = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const location = useLocation();
	const profileDetail = useSelector(
		(state) => state.ProfileReducer.userProfileDetail
	);

	const authData = useSelector((state) => state.authReducer.authData);

	// console.log("PROFILE AUTH ", authData);

	const searchTextRedux = useSelector(
		(state) => state.connectReducer.searchText
	);

	React.useEffect(() => {
		menuController.enable(true);
	}, []);
	const menuToggle = () => {
		menuController.toggle();
	};

	const [searchText, setSearchText] = useState("");

	const handleOnSearch = async (e) => {
		e.preventDefault();
		let route = location.pathname;
		if (searchText === "") {
			setSearchText("");
			await dispatch({ type: ACTION_TYPE.CLEAR_SEARCH_LIST });
			return;
		}
		if (route === ROUTES.Connect) {
			await dispatch(ConnectAction.getSearchConnections(searchText));
			await dispatch(ConnectAction.getSearchQuery(searchText));
		} else {
			await dispatch(ConnectAction.getSearchConnections(searchText));
			await dispatch(ConnectAction.getSearchQuery(searchText));
			history.push({
				pathname: ROUTES.Connect,
				state: searchText,
			});
		}
	};

	const handleChange = async (e) => {
		e.preventDefault();
		setSearchText(e.detail.value);
		// if (e.detail.value === "") {
		//   setSearchText("");
		//   await dispatch(ConnectAction.getSearchConnections(e.detail.value));
		// }
	};
	const handleEnter = async () => {
		let route = location.pathname;
		console.log(route);

		if (searchText === "") {
			return;
		}
		if (route === ROUTES.Connect) {
			// await dispatch(ConnectAction.getSearchConnections(searchText));
			// await dispatch(ConnectAction.getSearchQuery(searchText));
		} else {
			await dispatch(ConnectAction.getSearchConnections(searchText));
			await dispatch(ConnectAction.getSearchQuery(searchText));
			history.push({
				pathname: "/connect",
				state: searchText,
			});
		}
	};

	return (
		<IonHeader className="top-header-app">
			<IonToolbar className="hdr">
				<IonButtons
					slot="start"
					// className="kalyug"
				>
					<IonIcon
						className="barIcon"
						icon={menuOutline}
						onClick={(event) => {
							menuToggle();
						}}
					/>
					<IonImg
						className="logo-blue"
						style={{ maxWidth: "45px" }}
						src={require("../../images/HomeImages/wl.png")}
					/>
					<IonSearchbar
						onIonChange={(e) => {
							handleChange(e);
						}}
						className="searchBar"
						value={searchText}
					></IonSearchbar>
					<IonButton
						style={{ backgroundColor: "white", borderRadius: "10px" }}
						onClick={(e) => handleOnSearch(e)}
					>
						Search
					</IonButton>
				</IonButtons>
				<div className="right rightIcons adminRouteLink">
					{authData?.isAdmin && (
						<Link to={ROUTES.Admin}>
							<h6>Admin Dashboard</h6>
						</Link>
					)}

					<span className="profileImg">
						<IonImg
							className="childRight pic"
							style={{ width: "100%", height: "100%" }}
							src={profileDetail?.profile_image}
						/>
					</span>
				</div>
			</IonToolbar>
		</IonHeader>
	);
};

export default AuthHeader;
