import {
	IonContent,
	IonInput,
	IonButton,
	IonLabel,
	IonImg,
	IonSpinner,
	IonIcon,
} from "@ionic/react";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { ValidateLoginForm } from "./Validation";
import { ProfileAction } from "../../ReduxStore/Actions";
import { Toast } from "../../Utils/Toast";
import { useEffect } from "react";

const Admin = () => {
	const dispatch = useDispatch();
	const [state, setState] = React.useState();
	const [toast, setToast] = useState(false);
	const [isShowPassword, setPasswordType] = React.useState(false);
	const [errorData, setError] = React.useState();
	let profileData = useSelector((state) => state.ProfileReducer.adminDetail);
	console.log("GET PRO DT", profileData);

	const handleChange = (event) => {
		const { name, value } = event.target;

		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	useEffect(() => {
		(async () => {
			await dispatch(ProfileAction.retainAdminDetails());
		})();
	}, []);
	useEffect(() => {
		setState({
			email: profileData && profileData.email,
			password: profileData && profileData.password,
		});
	}, [profileData]);

	async function onSubmitForm(event) {
		event.preventDefault();
		const result = await ValidateLoginForm(state);
		console.log("Admin", result);

		if (result?.isFormValid) {
			setError({});
			const data = await dispatch(ProfileAction.updateAdminDetails(state));
			if (data?.status === true) {
				setToast(true);
				setTimeout(() => {
					setToast(false);
				}, 8000);
			}
		} else {
			setError(result?.error);
		}
		console.log("ADMIN FOM", state);
	}
	return (
		<IonContent>
			<div className="login">
				<div className="login-inner">
					<div className="login-head">
						<h2>Sender Email Setup</h2>
					</div>
					<div className="login-body">
						<IonLabel style={{ fontSize: "14px" }}>
							Sender Email specified below is currently utilized to send emails
							to website users.
							<strong>
								{" "}
								Only change this if you want to change the sender Email
							</strong>
						</IonLabel>
						<div style={{ marginBottom: "20px" }} />
						<form>
							<div className="form-group">
								<IonLabel>Sender Email</IonLabel>
								<IonInput
									type="text"
									value={state?.email ? state?.email : ""}
									name="email"
									className="form-control"
									onIonChange={(event) => {
										handleChange(event);
									}}
								></IonInput>
								<p> {errorData?.email && errorData.email[0]}</p>
							</div>
							<div className="form-group">
								<IonLabel>Sender Email Password</IonLabel>
								<div className="showpassword-input">
									<IonInput
										type={isShowPassword == true ? "text" : "password"}
										name="password"
										value={state?.password}
										onIonChange={(event) => {
											handleChange(event);
										}}
										className="form-control"
									></IonInput>
									{isShowPassword === true ? (
										<a onClick={() => setPasswordType(false)}>
											<IonIcon icon={eyeOutline} />
										</a>
									) : (
										<a onClick={() => setPasswordType(true)}>
											<IonIcon icon={eyeOffOutline} />
										</a>
									)}
								</div>
								<p> {errorData?.password && errorData.password[0]}</p>
							</div>

							<div className="button-login">
								<IonButton
									className="gradient-btn-blue"
									color="none"
									onClick={(event) => {
										onSubmitForm(event);
									}}
								>
									Submit
									{/* {(() => {
									if (loading) {
										return (
											<IonSpinner name="crescent" className="spinner-loader" />
										);
									}
								})()} */}
								</IonButton>

								{toast === true ? (
									<Toast>
										<Toast.Body>Credentials Updated</Toast.Body>
									</Toast>
								) : null}
							</div>
						</form>
					</div>
				</div>
			</div>
		</IonContent>
	);
};

export default Admin;
