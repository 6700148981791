import Api from "../../Api";
import { handleError } from "./commonAction";
import {
  ENDPOINTS,
  ACTION_TYPE,
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../Services/index";

export const loginUser = (body, props) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.LOGIN, body);
    console.log("RES loginnnnnnnnnnnnnnnnnn", res);
    await dispatch({
      type: ACTION_TYPE.LOGIN,
      payload: { data: res.data },
    });

    // dispatch({ type: "LOADING_COMPLETED" });
    if (res?.data?.status) {
      return { status: res?.data?.status, insertId: res?.data?.insertId };
    }else{
      return { status: false };
    }
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const signUpUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.SIGNUP, body);
    dispatch({
      type: ACTION_TYPE.SIGNUP,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    if (res?.data?.status && res?.data?.insertId) {
      return { status: res?.data?.status, insertId: res?.data?.insertId };
    }
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const matchToken = () => async (dispatch) => {
  try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );
    let token = await localStorage.getItem(APPLICATION_CONSTANTS.TOKEN);

    const res = await Api.get(
      ENDPOINTS.MATCH_TOKEN +
        `?email=${userData.email}&token=${token}&id=${userData.id}`
    );

    await dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const verifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.patch(ENDPOINTS.VERIFY_EMAIL, body);
    await dispatch({
      type: ACTION_TYPE.VERIFY_EMAIL,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(`/api/v1/users/change-password`, body);

    console.log("respass", res);
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const forgetPassword = (body, props) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.FORGET_PASSWORD, body);

    dispatch({
      type: ACTION_TYPE.FORGET_PASSWORD,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });

    
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const resetPassword = (body) => async (dispatch) => {
  try {
    // console.log("BODYYYYY",body)
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.RESET_PASSWORD, body);

    console.log("RESET_PASSWORD", res);
    dispatch({
      type: ACTION_TYPE.RESET_PASSWORD,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};


export const contactUs = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log("CONTACT", body);
    const res = await Api.post(ENDPOINTS.CONTACT_US, body);

    console.log("CONTACT", res);
    dispatch({
      type: ACTION_TYPE.CONTACT_US,
      payload: { data: res.data },
    });

    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};