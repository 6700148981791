import { IonContent } from "@ionic/react";
import React, { useEffect } from "react";
import ConnectionList from "../../components/ConnectsList/connectionList";
import MePostCardView from "../../components/MePostCardView/MePostCardView";
import { useDispatch, useSelector } from "react-redux";
import { NewsFeedAction } from "../../ReduxStore/Actions";
import "./newsFeed.css";
import { LikePostAction, UserPostsAction } from "../../ReduxStore/Actions";

const NewsFeed = (props) => {
	const dispatch = useDispatch();
	let userId = props?.match?.params?.userId;

	const postData = useSelector((state) => state.newsfeedReducer.newsfeed);
	useEffect(() => {
		let resPO = dispatch(NewsFeedAction.GetNewsFeed());
		// console.log("resPO", resPO);
	}, []);

	// console.log("EXTRACT", postData);

	return (
		<IonContent className="row ">
			<div className="newNewsFeedClass">
				<div className="newNewsFeed">
					<MePostCardView data={postData} />
				</div>
			</div>
		</IonContent>
	);
};
export default NewsFeed;
