import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	newsfeed: [],
};

export const newsfeedReducer = (state = initialState, action) => {
	console.log("news", action.type);
	console.log("news data", action.payload);

	switch (action.type) {
		case ACTION_TYPE.GET_NEWSFEED:
			return {
				...state,
				newsfeed: action.payload?.data,
			};

		default:
			return state;
	}
};
