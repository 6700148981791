import Api from "../../Api";
import { handleError } from "./commonAction";
import {
  ENDPOINTS,
  ACTION_TYPE,
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../Services/index";

export const getMyConnections = (id) => async (dispatch) => {
  console.log("resssssss coneectionsss");

  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.get(ENDPOINTS.GET_MY_CONNECTION + id);
    console.log("resssssss coneectionsss", res.data);
    dispatch({
      type: ACTION_TYPE.GET_MY_CONNECTION,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const getSearchConnections =
  (searchWord, state = {}) =>
  async (dispatch) => {
    try {
      let userData = await JSON.parse(
        localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
      );
      let role = userData.role;
      let userId = userData.id;
      console.log("state Action", state);

      const res = await Api.post(ENDPOINTS.SEARCH_CONNECTIONS, {
        searchWord,
        role,

        userId,
        state,
      });
      console.log("RESSSSSSSSSSSSSS search", res);
      dispatch({
        type: ACTION_TYPE.SEARCH_CONNECTIONS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };

export const getSearchQuery = (searchWord) => async (dispatch) => {
  console.log("searchword", searchWord);
  try {
    dispatch({
      type: ACTION_TYPE.SEARCH_TEXT,
      payload: searchWord,
    });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const addConnection = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    console.log(body, "AAAAAAAAAAAAAAAAAAAa", body);
    const res = await Api.post(ENDPOINTS.ADD_CONNECTION, body);
    console.log("ADD RES", res);
    dispatch({
      type: ACTION_TYPE.ADD_CONNECTION,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
