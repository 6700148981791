import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { CoverImageProfile, FeedProfile } from "./Components/index";
import { useDispatch, useSelector } from "react-redux";
import {
	TabsAction,
	ProfileAction,
	IndustryAction,
	UserPostsAction,
} from "../../ReduxStore/Actions/index";
import { APPLICATION_CONSTANTS } from "../../Services";
const Profile = () => {
	const dispatch = useDispatch();

	const TabsData = useSelector((state) => state.TabsReducer.TabsData);
	const authData = useSelector((state) => state.authReducer.authData);

	console.log("AUTHTHTHT", authData);
	const profileDetail = useSelector(
		(state) => state.ProfileReducer.userProfileDetail
	);
	const industryData = useSelector(
		(state) => state.IndustryReducer.industryData
	);
	console.log("indussstryyyyyyy", industryData);

	let userData = localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA);
	let user;
	if (userData) {
		user = JSON.parse(userData);
	}
	useEffect(() => {
		(async () => {
			await dispatch(ProfileAction.getUserDetails({ id: user.id }));
			await dispatch(UserPostsAction.GetUserPosts(user.id));
			await dispatch(
				TabsAction.GetTabsAction(APPLICATION_CONSTANTS.ALL_POST_TAB)
			);
			await dispatch(IndustryAction.GetIndustryAction());
			console.log("I AM FROM PROFILE");
		})();
	}, []);

	// useEffect(() => {
	// 	console.log("PROFILE");
	// }, []);

	return (
		<IonContent>
			<main className="col-12 col-md-12 main">
				<section className="core-rail profile">
					<CoverImageProfile
						authData={authData}
						profileDetail={profileDetail}
						isOwner={true}
					/>
					<FeedProfile
						TabsId={TabsData}
						authData={authData}
						profileDetail={profileDetail}
						industryData={industryData}
						isOwner={true}
					/>
				</section>
			</main>
		</IonContent>
	);
};

export default Profile;
