import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	userProfileDetail: {},
	userAdminDetail: {},
	adminDetail: [],
	message: "",
	status: null,
};

export const ProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.USER_PROFILE_DETAILS:
			return {
				...state,
				userProfileDetail: action.payload.data?.data,
			};
		case ACTION_TYPE.UPDATE_USER_PROFILE:
			return {
				...state,
				status: action.payload.data.status,
				message: action.payload.data.message,
			};
		case ACTION_TYPE.CLEAR_PROFILE_STATE:
			return {
				...state,
				status: null,
				message: "",
			};
		case ACTION_TYPE.ADMIN_PROFILE_DETAILS:
			return {
				...state,
				userAdminDetail: action.payload,
			};
		case ACTION_TYPE.RETAIN_ADMIN_DETAILS:
			return {
				...state,
				adminDetail: action.payload,
			};

		default:
			return state;
	}
};
