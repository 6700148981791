import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSpinner,
  IonIcon,
  IonSelect,
  IonRadio,
  IonSelectOption,
  IonListHeader,
  IonRadioGroup,
  IonList,
  IonItemDivider,
  IonItem,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import "./SignUp.css";
import { ValidateSignUpForm } from "./Validation";
import { ROUTES } from "../../Services/index";
import { AuthActions, IndustryAction } from "../../ReduxStore/Actions/index";
import GoogleAd from "../../components/GoogleAdsense/index";
import { Toast } from "../../Utils/Toast";
import { useSelector, useDispatch } from "react-redux";
import { stat } from "fs";
import firebase from "../../Services/FireBase";
import { menuController } from "@ionic/core";

const options = {
  cssClass: "custom-interface-popover",
};

const Signup = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState();
  const [errorData, setError] = React.useState();
  const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);
  const [isShowPassword, setPasswordType] = React.useState(false);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const authData = useSelector((state) => state.authReducer);
  const insertId = useSelector((state) => state.authReducer);
  const loading = useSelector((state) => state.commonReducer.loading);
  //  console.log("insertiiidddd",insertId)
  const industryData = useSelector(
    (state) => state.IndustryReducer.industryData
  );
  useEffect(() => {
    dispatch(IndustryAction.GetIndustryAction());
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  async function onSubmitForm(event) {
    event.preventDefault();
    if (state?.industry === "Other") {
      state.industry = state?.industry_other_detail;
    }
    const result = await ValidateSignUpForm(state);

    if (result?.isFormValid) {
      const status = await dispatch(AuthActions.signUpUser(state));
      console.log("STATESsssssssssssssssss", status);
      if (status && status?.status) {
        setState({});
        console.log("STATE run 123");

        await firebase
          .firestore()
          .collection("users")
          .add({
            name: state.fullName,
            id: status.insertId,
            email: state.email,
            password: state.password,
            URL: "",
            description: "",
            messages: [{ notificationId: "", number: 0 }],
          });
        console.log("STATE run");
        props.history.push(ROUTES.ThankYouPage);
      }
    } else {
      setError(result?.error);
    }
  }
  // console.log("authData in signup", authData);

  return (
    <IonContent>
      <GoogleAd slot="989038934" classNames="page-top" />
      <div className="signup">
        <div className="login-inner">
          {/* <div className="login-logo"> */}
          {/* <IonImg src={loginlogo} alt="logo"></IonImg> */}
          {/* </div> */}
          <div className="login-head">
            <h2>Sign Up</h2>
          </div>
          {(errorStatus === false || errorStatus === true) && errorMessage ? (
            <Toast
              isOpen={errorStatus === false ? true : false}
              message={errorMessage}
              isSuccess={errorStatus}
              isClearSuccess={true}
              isClearError={true}
            />
          ) : authData?.status ? (
            <Toast
              isOpen={authData?.status}
              message={authData?.message}
              isSuccess={authData?.status}
              duration={3000}
            />
          ) : null}

          <div className="login-body">
            <form>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Full name*</IonLabel>
                      <IonInput
                        type="text"
                        name="fullName"
                        className="form-control"
                        value={state?.fullName}
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.fullName[0] ? (
                        <p>{errorData?.fullName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Email address*</IonLabel>
                      <IonInput
                        type="email"
                        name="email"
                        value={state?.email}
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.email[0] ? (
                        <p>{errorData?.email[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Role*</IonLabel>
                      <IonSelect
                        interface="popover"
                        interfaceOptions={options}
                        placeholder="Select One"
                        name="role"
                        value={state && state.role}
                        onIonChange={(e) => handleChange(e)}
                        style={{ border: "1px solid #c7c7c7" }}
                      >
                        <IonSelectOption value={1}>Students</IonSelectOption>
                        <IonSelectOption value={2}>
                          Industry Professionals
                        </IonSelectOption>
                      </IonSelect>
                      {errorData?.role[0] ? <p>{errorData?.role[0]}</p> : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Industry*</IonLabel>
                      <div className="showpassword-input">
                        <IonSelect
                          interface="popover"
                          interfaceOptions={options}
                          placeholder="Select One"
                          name="industry"
                          value={state?.industry}
                          onIonChange={(e) => handleChange(e)}
                          style={{ border: "1px solid #c7c7c7" }}
                        >
                          {industryData &&
                            industryData.data &&
                            industryData.data.map((data) => {
                              return (
                                <div>
                                  <IonSelectOption value={data.industry_type}>
                                    {data.industry_type}
                                  </IonSelectOption>
                                </div>
                              );
                            })}
                        </IonSelect>
                        {errorData?.industry[0] ? (
                          <p>{errorData?.industry[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </IonCol>
                  {state?.industry === "Other" ? (
                    <IonCol size="12" size-md="12">
                      <div className="form-group">
                        <div className="showpassword-input">
                          <IonInput
                            type={"text"}
                            name="industry_other_detail"
                            className="form-control"
                            placeholder={"Type industry name"}
                            value={state?.industry_other_detail}
                            onIonChange={(event) => {
                              handleChange(event);
                            }}
                          ></IonInput>
                        </div>

                        {errorData?.industry[0] ? (
                          <p>{errorData?.industry[0]}</p>
                        ) : null}
                      </div>
                    </IonCol>
                  ) : null}
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Title*</IonLabel>
                      <IonInput
                        type="text"
                        name="title"
                        className="form-control"
                        value={state?.title}
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.title[0] ? (
                        <p>{errorData?.title[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Are you an Entreprenuer?*</IonLabel>
                      <div className="showpassword-input">
                        <IonSelect
                          interface="popover"
                          interfaceOptions={options}
                          placeholder="Select One"
                          name="is_entreprenuer"
                          value={state && state.is_entreprenuer}
                          onIonChange={(e) => handleChange(e)}
                          style={{ border: "1px solid #c7c7c7" }}
                        >
                          <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                          <IonSelectOption value={"No"}>No</IonSelectOption>
                        </IonSelect>
                        {errorData?.is_entreprenuer[0] ? (
                          <p>{errorData?.is_entreprenuer[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>Password*</IonLabel>
                      <div className="showpassword-input">
                        <IonInput
                          type={isShowPassword == true ? "text" : "password"}
                          name="password"
                          className="form-control"
                          value={state?.password}
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                        {isShowPassword === true ? (
                          <a onClick={() => setPasswordType(false)}>
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : (
                          <a onClick={() => setPasswordType(true)}>
                            <IonIcon icon={eyeOffOutline} />
                          </a>
                        )}
                      </div>

                      {errorData?.password[0] ? (
                        <p>{errorData?.password[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>Confirm password*</IonLabel>
                      <div className="showpassword-input">
                        <IonInput
                          type={
                            isShowConfirmPassword == true ? "text" : "password"
                          }
                          value={state?.confirmPassword}
                          name="confirmPassword"
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange(event);
                          }}
                        ></IonInput>
                        {isShowConfirmPassword === true ? (
                          <a onClick={() => setConfirmPasswordType(false)}>
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : (
                          <a onClick={() => setConfirmPasswordType(true)}>
                            <IonIcon icon={eyeOffOutline} />
                          </a>
                        )}
                      </div>

                      {errorData?.confirmPassword[0] ? (
                        <p>{errorData?.confirmPassword[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <div className="button-login">
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Sign Up
                  {(() => {
                    if (loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>

              <div class="haveaccount">
                <p>
                  Already have an account?{" "}
                  <Link to={ROUTES.Login}> Log In</Link>
                </p>
              </div>
              <div className="private-policy">
                <p>
                  By logging in, you agree to our<br></br>
                  <Link to={ROUTES.PRIVATE_POLICY}>
                    Privacy Policy
                  </Link> and{" "}
                  <Link to={ROUTES.TERMS_AND_CONDITION}>
                    Terms & Conditions
                  </Link>
                  .
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default Signup;
