import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonAlert } from "@ionic/react";

import {
  arrowRedoOutline,
  chatboxOutline,
  thumbsUp,
  thumbsDown,
  trashOutline,
} from "ionicons/icons";
import "../../DashboardModules/Profile/Profile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  TabsAction,
  ProfileAction,
  UserPostsAction,
  DislikePostAction,
  PostCommentAction,
} from "../../ReduxStore/Actions/index";
import moment from "moment";
import {
  SharePostAction,
  LikePostAction,
  NewsFeedAction,
} from "../../ReduxStore/Actions";
import { APPLICATION_CONSTANTS } from "../../Services/Strings/index";
import "./PostCard.css";
import { postComment } from "../../ReduxStore/Actions/postCommentAction";
import CryptoJS from "react-native-crypto-js";
import { withRouter } from "react-router";
const MePostCardView = (props) => {
  let userData = JSON.parse(
    localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
  );
  // console.log("LALA", userData);
  const dispatch = useDispatch();
  const postData = useSelector((state) => state.newsfeedReducer.newsfeed);
  const profileDetail = useSelector(
    (state) => state.ProfileReducer.userProfileDetail
  );
  let profileImage = profileDetail.profile_image;
  // console.log("PRODET", profileImage);
  const [deletePopup, setDeletePopup] = useState(false);
  const [postId, setPostId] = useState();
  const [like, setLike] = useState(false);
  const [share, setShare] = useState(false);
  const [comment, setComment] = useState("");

  // let userData = localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA);
  // let user;
  // if (userData) {
  // 	user = JSON.parse(userData);
  // }
  useEffect(() => {
    (async () => {
      // await dispatch(ProfileAction.getUserDetails({ id: userData.id }));
    })();
  }, []);

  const likePost = async (e, id) => {
    setLike(!like);
    if (id) {
      // console.log("Sending ID", id);
      await dispatch(LikePostAction.LikePost(id));
      await dispatch(NewsFeedAction.GetNewsFeed());
      await dispatch(UserPostsAction.GetUserPosts(props?.userId));
    }

    // console.log("LIKE KE LIYE", id);
  };
  const unlike = async (e, id) => {
    setLike(!like);
    if (id) {
      // console.log("Sending ID", id);
      await dispatch(DislikePostAction.DisikePost(id));
      await dispatch(NewsFeedAction.GetNewsFeed());
      await dispatch(UserPostsAction.GetUserPosts(props?.userId));
    }

    // console.log("LIKE KE LIYE", id);
  };

  const postComment = async (e, data) => {
    if (data) {
      await dispatch(
        PostCommentAction.postComment(data, comment, profileImage)
      );
      await dispatch(NewsFeedAction.GetNewsFeed());
      await dispatch(UserPostsAction.GetUserPosts(props?.userId));
      setComment("");
      document.getElementById(`post${data.id}`).value = "";
    }
  };

  // useEffect(() => {
  // 	let likeRes = setTimeout(() => {
  // 		dispatch(UserPostsAction.GetUserPosts(props?.userId));
  // 		console.log("INTO TIMEOUT");
  // 	}, 2000);

  // 	return () => {
  // 		clearTimeout(likeRes);
  // 	};
  // }, []);

  // useEffect(() => {
  //   console.log("MEPOSTCARD");
  // });

  // console.log("THIS IS PRPS FOR POSTCARD", props.data);
  // console.log("Thumbs");
  const onViewProfile = async (e, data) => {
    e.preventDefault();


    console.log("data",data)
    let selectedUserId = data.user_id;

    const userIdKey = await CryptoJS.AES.encrypt(
      selectedUserId.toString(),
      APPLICATION_CONSTANTS.REMEMBER_USER_SECRETE_KEY
    ).toString();
    var dataString = userIdKey
      .replace(/\+/g, "p1L2u3S")
      .replace(/\//g, "s1L2a3S4h")
      .replace(/=/g, "e1Q2u3A4l");
console.log("dataString",dataString)
    await props.history.push(`/view-profile/${dataString}`);
  };
  return (
    <section>
      <IonAlert
        isOpen={deletePopup}
        onDidDismiss={() => setDeletePopup(false)}
        cssClass="my-custom-class"
        header={"Delete"}
        message={"Are you sure?"}
        buttons={[
          {
            text: "Done",
            role: "done",
            handler: async () => {
              await dispatch(ProfileAction.DeletePost(postId));
              await dispatch(UserPostsAction.GetUserPosts(props?.userId));
              // console.log("done clicked");
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              // console.log("Cancel clicked");
            },
          },
        ]}
      ></IonAlert>
      <ul className="update_list">
        {props &&
          props.data &&
          props.data.map((data, index) => {
            let constant = data.thumsup;
            let person = data?.thumsup?.filter(
              (person) => person.id === userData?.id
            );

            var time = moment(data.created_ts).format("hh:mm a");
            var date = moment(data.created_ts).format("MM/DD/YYYY");
            // var date  = moment(data.created_ts).fromNow()

            return (
              <li>
                <div className="share-update-card">
                    <div className="share-update-card__header"   onClick={(e) => {
                                      onViewProfile(e, data);
                                    }}> 
                    <img
                      style={{ backgroundColor: "black" }}
                      className="share-update-card__actor-image artdeco-entity-image--square-3 artdeco-entity-image"
                      src={data.profile_image}
                    ></img>
                    <div className="share-update-card__actor-info">
                      <h3 className="share-update-card__actor-text">
                        {data.full_name}
                      </h3>
                      <h4 className="card__post-date">
                        {date} - {time}{" "}
                      </h4>
                      </div>
                    </div>
                  <div className="ellipsis-menu share-update-card__ellipsis-menu">
                    <div className="collapsible-dropdown">
                      <div>
                        {data?.isShared && data?.isShared === "true" && (
                          <h6 style={{ fontSize: "10px" }}>( Shared )</h6>
                        )}
                      </div>
                      {props?.isOwner ? (
                        <button className="collapsible-dropdown__button ellipsis-menu__trigger">
                          <IonIcon
                            onClick={() => {
                              setPostId(data.id);
                              setDeletePopup(true);
                            }}
                            icon={trashOutline}
                          ></IonIcon>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="caption">
                    <h6>{data.description}</h6>
                  </div>
                  <div className="imgSocial">
                    {data.image ? (
                      <ul className="share-images">
                        <li className="share-images__image-container">
                          {data.image !== null && (
                            <div
                              className="share-images__image"
                              style={{
                                backgroundImage: `url(${data.image})`,
                              }}
                            ></div>
                          )}
                        </li>
                      </ul>
                    ) : null}

                    <div className="social-action-bar">
                      {data.id && person?.length > 0 ? (
                        <button
                          onClick={(e) => {
                            // setLike(!like);
                            unlike(e, data.id);
                          }}
                          className="social-action-bar__button"
                        >
                          <IonIcon
                            style={{ color: "#296863" }}
                            className="social-action-bar__icon"
                            icon={thumbsDown}
                          ></IonIcon>{" "}
                          <span style={{ color: "#296863" }}>Unlike</span>
                        </button>
                      ) : (
                        <button
                          onClick={(e) => {
                            likePost(e, data.id);
                          }}
                          className="social-action-bar__button"
                        >
                          <IonIcon
                            className="social-action-bar__icon"
                            icon={thumbsUp}
                          ></IonIcon>{" "}
                          <span>Like</span>
                        </button>
                      )}

                      <div class="line"></div>
                      <button className="social-action-bar__button">
                        <IonIcon
                          className="social-action-bar__icon"
                          icon={chatboxOutline}
                        ></IonIcon>{" "}
                        <span>Comment</span>{" "}
                      </button>
                      <div class="line"></div>

                      <button
                        onClick={(e) => {
                          // doSomething(e, data.id);
                          setPostId(data.id);
                          setShare(true);
                        }}
                        className="social-action-bar__button"
                      >
                        <IonIcon
                          className="social-action-bar__icon"
                          icon={arrowRedoOutline}
                        ></IonIcon>
                        <span>Share</span>
                      </button>
                      <IonAlert
                        isOpen={share}
                        onDidDismiss={() => setShare(false)}
                        cssClass="my-custom-class"
                        // header={"Share"}
                        message={"Do you want to share this post"}
                        buttons={[
                          {
                            text: "Share",
                            role: "share",
                            handler: async () => {
                              // console.log(postId);
                              await dispatch(SharePostAction.SharePost(postId));
                              // console.log("done clicked");
                            },
                          },
                          {
                            text: "Cancel",
                            role: "cancel",
                            handler: () => {
                              console.log("Cancel clicked");
                            },
                          },
                        ]}
                      ></IonAlert>
                    </div>
                    {props?.thumsup && <p>THUMBSUP</p>}
                    {data.comment?.map((comment) => {
                      return (
                        <div className="">
                          <div className="commentBoxStyle">
                            <div className="breakInParts">
                              <img
                                className="commentBoxProfileImage"
                                src={comment.profile_image}
                              />
                              <p className="commentBoxComment">
                                <strong>{comment.username}</strong>
                                {comment.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {data.id ? (
                      <div className="postCommentBoxSection">
                        <input
                          placeholder="Add a comment"
                          className="postCommentBox"
                          type="text"
                          id={`post${data.id}`}
                          // value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <IonButton
                          className="button-green comment-button-green-mobile"
                          onClick={(e) => {
                            postComment(e, data);
                          }}
                          disabled={!comment}
                        >
                          Add Comment
                        </IonButton>
                      </div>
                    ) : null}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </section>
  );
};
export default withRouter( MePostCardView);
