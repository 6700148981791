import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon,
  IonPage,
  IonAlert,
} from "@ionic/react";
import {
  arrowRedoOutline,
  chatboxOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  createOutline,
  personAdd,
  personAddOutline,
  returnUpForwardOutline,
  thumbsUpOutline,
  trashOutline,
} from "ionicons/icons";
import "../../Profile.css";
import MePostCardView from "../../../../components/MePostCardView/MePostCardView";
import UserInfo from "../UserInfo/UserInfo";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "react-native-crypto-js";
import { withRouter } from "react-router-dom";
import { ConnectAction } from "../../../../ReduxStore/Actions/index";
import { APPLICATION_CONSTANTS, ROUTES } from "../../../../Services";
import { Link } from "react-router-dom";
const ConnectionList = (props) => {
  let dispatch = useDispatch();
  let myConnection = useSelector((state) => state.connectReducer.myConnection);
  const loading = useSelector((state) => state.commonReducer.loading);

  let userData = localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA);
  let id = JSON.parse(userData).id;

  useEffect(() => {
    (async () => {
      await dispatch(ConnectAction.getMyConnections(id));
    })();
  }, []);

  const onViewProfile = async (e, data) => {
    e.preventDefault();
    let selectedUserId = data.id;

    const userIdKey = await CryptoJS.AES.encrypt(
      selectedUserId.toString(),
      APPLICATION_CONSTANTS.REMEMBER_USER_SECRETE_KEY
    ).toString();
    var dataString = userIdKey
      .replace(/\+/g, "p1L2u3S")
      .replace(/\//g, "s1L2a3S4h")
      .replace(/=/g, "e1Q2u3A4l");

    await props.history.push(`/view-profile/${dataString}`);
  };

  return (
    <div className="col-md-7 col-sm-12 ContactList " style={{padding:0,marginRight:8}}>
      <div className="share-details-card">
        <ul className="connectionsAll">
          <li className="reusableli">
            <div className="entry_list">
              {myConnection.length > 0 ? (
                myConnection?.map((data, i) => {
                  return (
                    <div  key={i} style={{display: "inline-block",width: '100%'}}>
                      <div className="col-md-4 col-sm-12" style={{float:'left'}}>
                      <div className="entry_list_items">
                      <div className="entity_list__image">
                      {/* style={{ display: "flex", alignItems: "center" }} */}
                        <div style={{ textAlign:'center' }}>
                          <img src={data?.profile_image}></img>
                        </div>
                      </div>
                      <div className="entity_list_content">
                        <div className="entity_list_content_info">
                          <h5>{data?.full_name}</h5>
                          <p>{data?.industry}</p>
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="col-md-8 col-sm-12" style={{float:'right'}}>
                      <div className="entry_list_items">
                      <div className="entity_list_actions">
                        <IonButton
                          className="button-green ion-green-button"
                          onClick={(e) => {
                            onViewProfile(e, data);
                          }}
                        >
                          View Profile
                        </IonButton>
                      </div>
                      <div className="entity_list_actions">
                        <Link to={ROUTES.Chat} style={{width: '100%'}}>
                          <IonButton className="button-green ion-green-button">
                            Message
                          </IonButton>
                        </Link>
                      </div>
                      </div>
                      </div>
                    </div>
                  );
                })
              ) : loading === true ? (
                <div style={{ padding: 10, textAlign: "center" }}>
                  <h3>Loading...</h3>
                </div>
              ) : (
                <div style={{ padding: 10, textAlign: "center" }}>
                  <h3>No Connections</h3>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default withRouter(ConnectionList);
