import { IonFooter, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { ROUTES } from "../../Services";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
} from "ionicons/icons";

const Footer = () => {
  return (
    <footer>
      <div className="InFoot" id="footer">
        <div className="container">
          <div className="footIn">
            <div className="col-md-4 col-sm-6 col-xs-12 col text-center">
              <img
                className="logoo"
                src={require("../../images/HomeImages/wl.png")}
              ></img>
              <h4>
                StudentProCONNECT<sup>®</sup>
              </h4>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 col socialmedia">
              <div className="mediaIcon">
                <a
                  href="https://www.facebook.com/StudentProConnect"
                  target="_blank"
                >
                  <IonIcon icon={logoFacebook}></IonIcon>
                </a>
              </div>
              <div className="mediaIcon">
                <a href="https://twitter.com/StudentProCONN1" target="_blank">
                  <IonIcon icon={logoTwitter}></IonIcon>
                </a>
              </div>
              <div className="mediaIcon">
                <a
                  href="https://www.instagram.com/studentproconnect1/"
                  target="_blank"
                >
                  <IonIcon icon={logoInstagram}></IonIcon>
                </a>
              </div>
              <div className="mediaIcon">
                <a
                  href="https://www.youtube.com/channel/UCVLJTWUeXgS2VXP8IifoaJw"
                  target="_blank"
                >
                  <IonIcon icon={logoYoutube}></IonIcon>
                </a>
              </div>
            </div>
            {/* <div className="col-md-4 hidden-sm col">&nbsp;</div> */}
            <div className="col-md-4 col-sm-6 col-xs-12 col footTabs">
              <ul>
                <li>
                  <Link to={ROUTES.Home}>Home</Link>
                </li>
                <li>
                  <Link to={ROUTES.Student}>Students</Link>
                </li>
                <li>
                  <Link to={ROUTES.Industry_Professional}>
                    Industry Professionals
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.Entrepreneurs}>Entrepreneurs</Link>
                </li>
                <li>
                  <Link to={ROUTES.ContactUs}>Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyRight">
          <p>
            © 2020 by StudentProConnect<sup>@</sup> LLC. |{" "}
            <Link to={ROUTES.TERMS_AND_CONDITION}>Terms & Conditions</Link> |{" "}
            <Link to={ROUTES.PRIVATE_POLICY}>Privacy Policy</Link>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
