import React from "react";
import { Switch } from "react-router-dom";
import WebsiteModule from "../WebsiteModule/WebsiteModule";
import {
	Login,
	Profile,
	ViewUserProfile,
	SignUp,
	EmailVerify,
	Connect,
	ForgetPassword,
	ResetPassword,
	DSF,
	ThankYouPage,
	NewsFeed,
	AdminPage,
} from "../DashboardModules/index";
import PrivateRoute from "./PrivateRoutes";
import { ROUTES } from "../Services/index";

import Chat from "../DashboardModules/Chat/Chat";

const PublicRoutes = () => {
	return (
		// <React.Fragment>
		<Switch>
			<PrivateRoute
				path={ROUTES.ForgetPassword}
				component={ForgetPassword}
				exact
			/>
			<PrivateRoute path={ROUTES.Profile} component={Profile} exact />
			<PrivateRoute path={ROUTES.Chat} component={Chat} exact />
			<PrivateRoute
				path={ROUTES.ViewProfile}
				component={ViewUserProfile}
				exact
			/>
			<PrivateRoute path={ROUTES.Connect} component={Connect} exact />
			<PrivateRoute path={ROUTES.NEWS_FEED} component={NewsFeed} exact />
			<PrivateRoute path={ROUTES.SignUp} component={SignUp} exact />
			<PrivateRoute path={ROUTES.Admin} component={AdminPage} exact />
			<PrivateRoute path={ROUTES.ThankYouPage} component={ThankYouPage} exact />

			<PrivateRoute path={ROUTES.Login} component={Login} exact />
			<PrivateRoute path={ROUTES.EmailVerify} component={EmailVerify} />
			<PrivateRoute
				path={ROUTES.ResetPassword}
				component={ResetPassword}
				exact
			/>
			<PrivateRoute path={"/dsf"} component={DSF} exact />
			<WebsiteModule></WebsiteModule>
		</Switch>
		// </React.Fragment>
	);
};

export default PublicRoutes;
