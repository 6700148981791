import { ACTION_TYPE ,APPLICATION_CONSTANTS} from "../../Services/index";
export const initialState = {
 industryData:[]
  };
  
  export const IndustryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE.GET_INDUSTRY:
            return {
              ...state,
              industryData: action.payload,
            };
      default:
        return state;
    }
  };
  