import React from "react";
import {
	IonHeader,
	IonTitle,
	IonToolbar,
	IonIcon,
	IonImg,
	IonButtons,
} from "@ionic/react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services/index";
import { isPlatform } from "@ionic/react";
import { withRouter } from "react-router";
import "./Header.css";
import { menuOutline } from "ionicons/icons";
import { menuController } from "@ionic/core";
const Header = (props) => {
	const menuToggle = () => {
		menuController.toggle();
	};
	React.useEffect(() => {
		// console.log("P", props, location.pathname, ROUTES.Student);
	}, []);
	let { location } = props;
	return (
		<IonHeader id="header" class="fixed-top headerWeb">
			<IonToolbar className="hdr">
				<div className="mainHeaderWeb">
					<div className="container">
						<IonButtons
							slot="start"
							className="kalyug"
							onClick={(event) => {
								menuToggle();
							}}
						>
							<IonIcon className="barIcon" icon={menuOutline} />
						</IonButtons>
						{/* <button type="button" >
            <IonIcon className="barIcon" icon={menuOutline} />
          </button> */}

						<div className="logoTitle">
							<IonImg
								className="logo-blue logoImg"
								// style={{ maxWidth: "45px" }}
								src={require("../../images/HomeImages/wl.png")}
							/>

							<div className="logo float-left">
								<Link to={ROUTES.Home} style={{ color: "white" }}>
									StudentProCONNECT<sup>®</sup>
								</Link>
							</div>
						</div>

						<nav class="main-nav float-right d-none d-lg-block">
							<ul className="mainNav">
								<li
									className={location.pathname === ROUTES.Home ? "active" : ""}
								>
									<Link to={ROUTES.Home}>Home</Link>
								</li>
								<li
									className={
										location.pathname === ROUTES.Student ? "active" : ""
									}
								>
									<Link to={ROUTES.Student}>Students</Link>{" "}
								</li>
								<li
									className={
										location.pathname === ROUTES.Industry_Professional
											? "active"
											: ""
									}
								>
									<Link to={ROUTES.Industry_Professional}>
										Industry Professionals
									</Link>{" "}
								</li>
								<li
									className={
										location.pathname === ROUTES.Entrepreneurs ? "active" : ""
									}
								>
									<Link to={ROUTES.Entrepreneurs}>Entrepreneurs</Link>{" "}
								</li>
								<li
									className={
										location.pathname === ROUTES.Podcast ? "active" : ""
									}
								>
									<Link to={ROUTES.Podcast}>Podcast</Link>{" "}
								</li>
								{/* <li
									className={
										location.pathname === ROUTES.Entrepreneurs ? "active" : ""
									}
								>
									<Link to={ROUTES.Entrepreneurs}>Entrepreneurs</Link>{" "}
								</li> */}

								<li
									className={
										location.pathname === ROUTES.ContactUs ? "active" : ""
									}
								>
									<Link to={ROUTES.ContactUs}>Contact Us</Link>{" "}
								</li>

								{localStorage.getItem("token") ? (
									<li>
										<Link
											to={ROUTES.Profile}
											style={{ color: "yellow", fontWeight: "600" }}
										>
											Account
										</Link>{" "}
									</li>
								) : (
									<li>
										<Link to={ROUTES.Login} style={{ padding: "0px 10px" }}>
											<Button
												style={{
													borderRadius: "2rem",
													padding: "4px 35px",
													background: "#ffffff",
													borderColor: "#0D6763",
													color: "black",
												}}
											>
												Login
											</Button>
										</Link>
										<Link to={ROUTES.SignUp} style={{ padding: "0px 10px" }}>
											<Button
												style={{
													borderRadius: "2rem",
													padding: "4px 35px",
													background: "#ffffff",
													borderColor: "#0D6763",
													color: "black",
												}}
											>
												Sign Up
											</Button>
										</Link>
									</li>
								)}
							</ul>
						</nav>
					</div>
				</div>
			</IonToolbar>
		</IonHeader>
	);
};

export default withRouter(Header);
