import {
	IonContent,
	IonInput,
	IonButton,
	IonLabel,
	IonImg,
	IonSpinner,
	IonIcon,
} from "@ionic/react";
import { IonItem, IonCheckbox } from "@ionic/react";
import React, { useState } from "react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { menuController } from "@ionic/core";
import { ValidateLoginForm } from "./Validation";
import "./Login.css";
import { withRouter, Link } from "react-router-dom";
import { ROUTES, APPLICATION_CONSTANTS } from "../../Services/index";
import CryptoJS from "react-native-crypto-js";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions, UserPostsAction } from "../../ReduxStore/Actions/index";
import { Toast } from "../../Utils/Toast";
import firebase from "../../Services/FireBase";
import LoginString from "./LoginString";
import { stat } from "fs";

const Login = (props) => {
	const dispatch = useDispatch();
	const [state, setState] = React.useState();
	const [errorData, setError] = React.useState();
	const [checked, setChecked] = useState();

	const [isShowPassword, setPasswordType] = React.useState(false);
	const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
	const errorStatus = useSelector((state) => state.commonReducer.status);
	const loading = useSelector((state) => state.commonReducer.loading);

	React.useEffect(() => {
		(async () => {
			let rememberme = await localStorage.getItem(
				APPLICATION_CONSTANTS.REMEMBER_ME
			);
			if (rememberme) {
				setChecked(true);
				rememberme = CryptoJS.AES.decrypt(
					rememberme,
					APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
				);
				rememberme = rememberme.toString(CryptoJS.enc.Utf8);
				rememberme = JSON.parse(rememberme);
				setState(rememberme);
				// setUserData(rememberme);
			} else {
				setChecked(false);
			}
		})();
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;

		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	async function onSubmitForm(event) {
		event.preventDefault();
		const result = await ValidateLoginForm(state);

		if (result?.isFormValid) {
			setError({});
			if (checked) {
				const rememberme = CryptoJS.AES.encrypt(
					JSON.stringify(state),
					APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
				).toString();
				localStorage.setItem(APPLICATION_CONSTANTS.REMEMBER_ME, rememberme);
			} else {
				localStorage.removeItem(APPLICATION_CONSTANTS.REMEMBER_ME);
			}

			const status = await dispatch(AuthActions.loginUser(state, props));

			if (status && status?.status) {
				await firebase
					.auth()
					.createUserWithEmailAndPassword(state?.email, state?.password)
					.catch((err) => {
						console.log("connectedddddd in iffff sign err", err);
					});

				await firebase
					.auth()
					.signInWithEmailAndPassword(state?.email, state?.password)
					.then(async (res) => {})
					.catch((err) => {
						console.log("connectedddddd in iffff err", err);
					});

				await firebase
					.firestore()
					.collection("users")
					.where("id", "==", status.insertId)
					.get()
					.then(function (querySnapshot) {
						console.log("loginnnnnnnnnn");
						dispatch({ type: "LOADING_COMPLETED" });
						querySnapshot.forEach(function (doc) {
							const currentdata = doc.data();
							localStorage.setItem(LoginString.FirebaseDocumentId, doc.id);
							localStorage.setItem(LoginString.ID, currentdata.id);
							localStorage.setItem(LoginString.Name, currentdata.name);
							localStorage.setItem(LoginString.Email, currentdata.email);
							localStorage.setItem(LoginString.Password, currentdata.password);
							localStorage.setItem(LoginString.PhotoURL, currentdata.URL);
							localStorage.setItem(
								LoginString.Description,
								currentdata.description
							);
						});
					});
				let userData = JSON.parse(
					localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
				);
				if (userData.isAdmin !== null) {
					props.history.push(ROUTES.Admin);
				} else {
					props.history.push(ROUTES.Profile);
					// window.location.reload();
					// setTimeout(() => {
					// 	dispatch(UserPostsAction.GetUserPosts(userData.id));
					// }, 1000);
					// window.location.reload();
				}
			}
		} else {
			setError(result?.error);
		}
	}

	return (
		<IonContent>
			<div className="login">
				<div className="login-inner">
					{/* <div className="login-logo"><IonImg src={loginlogo} /></div> */}
					<Toast
						isOpen={errorStatus === false ? true : false}
						message={errorMessage}
						isSuccess={errorStatus}
						isClearSuccess={true}
						isClearError={true}
					/>
					<div className="login-head">
						<h2>Log In</h2>
					</div>
					<div className="login-body">
						<form>
							<div className="form-group">
								<IonLabel>Email Address</IonLabel>
								<IonInput
									type="text"
									value={state?.email ? state?.email : ""}
									name="email"
									className="form-control"
									onIonChange={(event) => {
										handleChange(event);
									}}
								></IonInput>
								<p> {errorData?.email && errorData.email[0]}</p>
							</div>
							<div className="form-group">
								<IonLabel>Password</IonLabel>
								<div className="showpassword-input">
									<IonInput
										type={isShowPassword == true ? "text" : "password"}
										name="password"
										value={state?.password}
										onIonChange={(event) => {
											handleChange(event);
										}}
										className="form-control"
									></IonInput>
									{isShowPassword === true ? (
										<a onClick={() => setPasswordType(false)}>
											<IonIcon icon={eyeOutline} />
										</a>
									) : (
										<a onClick={() => setPasswordType(true)}>
											<IonIcon icon={eyeOffOutline} />
										</a>
									)}
								</div>

								<p> {errorData?.password && errorData.password[0]}</p>
							</div>

							<div className="check-forgot">
								<div className="custom-checkbox-inline">
									<IonCheckbox
										className="custom-checkbox"
										checked={checked}
										onIonChange={(e) => setChecked(e.detail.checked)}
									/>
									<IonLabel>Remember Me</IonLabel>
								</div>

								<div className="forgot-password">
									<Link to={ROUTES.ForgetPassword} className="forgot-text">
										Forgot Password?
									</Link>
								</div>
							</div>

							<div className="button-login">
								<IonButton
									className="gradient-btn-blue"
									color="none"
									onClick={(event) => {
										onSubmitForm(event);
									}}
								>
									Log In
									{(() => {
										if (loading) {
											return (
												<IonSpinner
													name="crescent"
													className="spinner-loader"
												/>
											);
										}
									})()}
								</IonButton>
							</div>

							<div className="haveaccount">
								<p>
									Don't have an account? <Link to={ROUTES.SignUp}>Sign Up</Link>
								</p>
							</div>

							<div className="private-policy">
								<p>
									By logging in, you agree to our<br></br>
									<Link to={ROUTES.PRIVATE_POLICY}>
										Privacy Policy
									</Link> and{" "}
									<Link to={ROUTES.TERMS_AND_CONDITION}>
										Terms & Conditions
									</Link>
									.
								</p>
							</div>
						</form>
						{/* <PrivatePolicy showPrivatePolicy={privatePolicy} setPrivatePolicy={() => { setPrivatePolicy(false) }} />
            <TNC showPrivatePolicy={tnc} setPrivatePolicy={() => { setTnc(false) }} /> */}
					</div>
				</div>
			</div>
		</IonContent>
	);
};

export default withRouter(Login);
