import { IonContent } from "@ionic/react";
import React from "react";
import "./Entrepreneurs.css";
import Footer from "../../components/Footer/Footer";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../Services";
import { useEffect } from "react";
import { ACTION_TYPE } from "../../Services";
import { useDispatch } from "react-redux";
import { APPLICATION_CONSTANTS } from "../../Services/Strings/index";

const Professionals = (props) => {
	return (
		<IonContent>
			<section className="ProfessionalsTop">
				<div className="table">
					<div className="table-cell">
						{/* <img
                className="logo"
                src={require("../../images/ProfessionalsImages/wl.png")}
              /> */}
						<h1 className="headingMain">Entrepreneurs</h1>
					</div>
				</div>
			</section>

			<section className="ProfessionalsSection2 container">
				<div className="col-md-6 col-sm-12 ">
					<p className="TopPara">
						<b>
							StudentProCONNECT<sup>®</sup>
						</b>
						&nbsp; provides entrepreneurs with a platform to showcase your
						start-ups as well as gain valuable feedback from other entrepreneurs
						in the industry to help them achieve growth & success.
					</p>
					<ol className="gradient-list">
						<li>
							Network & partner up with other entrepreneurs to help your
							start-up grow
						</li>
						<li>
							Share and read insight and experiences from entrepreneurs in your
							respective industry
						</li>
						<li>
							Find students eager to gain experience in your industry who can
							lend a helping hand
						</li>
						<li>Free publicity & increased exposure for your start-up</li>
					</ol>
				</div>
				<div className="col-md-6 col-sm-12">
					<img
						alt="image"
						style={{ maxWidth: "100%" }}
						src={require("../../images/EntreprenuerImages/Entrepreneur.png")}
					/>
				</div>
			</section>
			{localStorage.getItem("token") ? null : (
				<div className="loginSignup" style={{ textAlign: "center" }}>
					<span style={{ margin: "2px 10px" }}>
						<Link to={ROUTES.Login}>
							<Button
								style={{
									borderRadius: "2rem",
									padding: "5px 35px",
									background: "#ffffff",
									borderColor: "#092C05",
									color: "black",
									width: "250px",
									marginRight: "20px",
								}}
							>
								Login{" "}
							</Button>
						</Link>{" "}
						<Link to={ROUTES.SignUp}>
							<Button
								style={{
									borderRadius: "2rem",
									padding: "5px 35px",
									background: "#ffffff",
									borderColor: "#092C05",
									color: "black",
									width: "250px",
								}}
							>
								Sign Up{" "}
							</Button>
						</Link>{" "}
					</span>
				</div>
			)}

			<Footer></Footer>
		</IonContent>
	);
};

export default Professionals;
