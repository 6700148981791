import {
  ENDPOINTS,
  ACTION_TYPE,
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../Services/index";
import Api from "../../Api";

export const GetUserPosts = (id) => async (dispatch) => {
  try {
    const res = await Api.get(ENDPOINTS.USER_POSTS + `${id}`);
    //   console.log("alll user postssss  action ",res.data)
    //   console.log(" ENDPOINTS.USER_POSTS ENDPOINTS.USER_POSTS",ENDPOINTS.USER_POSTS+userData.id)

    await dispatch({
      type: ACTION_TYPE.USER_POSTS,
      payload: res.data,
    });
    // dispatch({ type: "LOADING_COMPLETED" });
    // return res.data;
  } catch (error) {
    // dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
