import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../Services/index";
import { IonPage } from "@ionic/react";
import AuthHeader from "../components/AuthHeader/AuthHeader";
import Header from "../components/Header/Header";

class PrivateGuard extends Component {
  dontShowAfterLogin = [
    ROUTES.SignUp,
    ROUTES.Login,
    ROUTES.EmailVerify,
    ROUTES.ForgetPassword,
    ROUTES.ResetPassword,
    ROUTES.ThankYouPage
  ];
  render() {
    const route = this.props.location?.pathname;
    let returnData = "";

    if (localStorage.getItem("token") && localStorage.getItem("userData")) {
      if (!this.dontShowAfterLogin.includes(route)) {
        returnData = (
          <>
            <AuthHeader />
            <Route {...this.props} />
          </>
        );
      } else {
        returnData = (
          <>
            <Header />
            <Redirect
              to={{
                pathname: "/",
                state: { from: this.props.location },
              }}
            />
          </>
        );
      }
    } else {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = (
          <>
            <Header />
            <Route {...this.props} />
          </>
        );
      } else {
        returnData = (
          <>
            <Header />
            <Redirect
              to={{
                pathname: "/",
                state: { from: this.props.location },
              }}
            />
          </>
        );
      }
    }

    return <IonPage>{returnData}</IonPage>;
  }
}

export default PrivateGuard;
