import React, { useState, useEffect } from "react";

import "../../Profile.css";
import MePostCardView from "../../../../components/MePostCardView/MePostCardView";
import UserInfo from "../UserInfo/UserInfo";
import ConnectionList from "../ConnectionList/ConnectionList";
import { APPLICATION_CONSTANTS } from "../../../../Services/index";
import { useDispatch, useSelector } from "react-redux";
import { UserPostsAction } from "../../../../ReduxStore/Actions/index";
import { LikePostAction } from "../../../../ReduxStore/Actions";
import CryptoJS from "react-native-crypto-js";
import { withRouter } from "react-router";

const FeedProfile = (props) => {
	console.log("props FeedProfile",props)
	const dispatch = useDispatch();
	const [ownerId, setOwnerId] = useState();
	let userId = props?.match?.params?.userId;
	useEffect(() => {
		(async () => {
			if (props?.isOwner) {
				let userData = await JSON.parse(
					localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
				);
				// userId= userData.id
				setOwnerId(userData.id);
				console.log("QQQQQ", props);
				await dispatch(UserPostsAction.GetUserPosts(userData.id));
			} else {
				userId = await userId
					.replace(/p1L2u3S/g, "+")
					.replace(/s1L2a3S4h/g, "/")
					.replace(/e1Q2u3A4l/g, "=");
				userId = CryptoJS.AES.decrypt(
					userId,
					APPLICATION_CONSTANTS.REMEMBER_USER_SECRETE_KEY
				);

				userId = userId.toString(CryptoJS.enc.Utf8);
				console.log("PPPP", props);
				setOwnerId(userId);
				await dispatch(UserPostsAction.GetUserPosts(userId));
			}
			console.log("I AM FROM FEEDPROFILE");
		})();
	}, []);

	useEffect(() => {
		console.log("FEEDPROFILE");
	}, []);

	const PostsData = useSelector(
		(state) => state.UserPostsReducer.userPostsData
	);

	return (
		<section className="col-12 col-md-12 updates  section-container">
			<div className="feed">
				{props &&
				props.TabsId &&
				props.TabsId === APPLICATION_CONSTANTS.ALL_POST_TAB ? (
					<div className="col-sm-12 col-md-7 allposts">
						<MePostCardView
							data={PostsData.data}
							isOwner={props?.isOwner}
							userId={ownerId}
						/>
					</div>
				) : (
					<ConnectionList />
				)}

				<div className="col-sm-12 col-md-5 Details">
					<UserInfo
						authData={props?.authData}
						profileDetail={props?.profileDetail}
						isOwner={props?.isOwner}
						industryData={props?.industryData}
					/>
				</div>
			</div>
			{/* // ) : ( // )} */}
		</section>
	);
};
export default withRouter(FeedProfile);
