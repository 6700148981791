import React, { useEffect, useState, useRef } from "react";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon,
  IonPage,
  IonAlert,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  arrowRedoOutline,
  chatboxOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  createOutline,
  personAdd,
  personAddOutline,
  returnUpForwardOutline,
  thumbsUpOutline,
  thumbsUpSharp,
  trashOutline,
  addCircleOutline,
} from "ionicons/icons";
import { ProfileAction } from "../../../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_CONSTANTS, ACTION_TYPE } from "../../../../Services/index";

import { Toast } from "../../../../Utils/Toast";
import "../../Profile.css";
const options = {
  cssClass: "custom-interface-popover",
};

const UserInfo = (props) => {
  // console.log("props.industry",props.industryData.data)
  const [editPopup, setEditPopup] = useState(true);
  const [state, setState] = React.useState();
  const [industry, setIndustry] = React.useState();
  const profileData = useSelector((state) => state.ProfileReducer);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const dispatch = useDispatch();

  let userData = localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA);
  let user;
  if (userData) {
    user = JSON.parse(userData);
  }
  useEffect(() => {
    (async () => {
      setState(props?.profileDetail);
    })();
  }, [props?.profileDetail]);

  const handleChange = (event, type) => {
    const { name, value } = event.target;
    // console.log("value out of if", name, value);
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const updateProfileDetails = async () => {
    // console.log("statestate", state);
    if (state?.industry === "Other") {
      state.industry = state?.industry_other_detail;
    }
    await dispatch(
      ProfileAction.updateProfileDetails(state, {
        id: user.id,
        role: user.role,
      })
    );
    setEditPopup(true);
  };
  return (
    <div className="Editable details">
      <div className="share-details-card">
        {profileData?.status == true ? (
          <Toast
            isOpen={profileData?.status}
            message={profileData?.message}
            isSuccess={profileData?.status}
            isClearSuccess={true}
            isClearError={true}
            actionType={ACTION_TYPE.CLEAR_PROFILE_STATE}
          />
        ) : errorStatus === false ? (
          <Toast
            isOpen={errorStatus === false ? true : null}
            message={errorMessage}
            isSuccess={errorStatus}
            isClearSuccess={true}
            isClearError={true}
          />
        ) : null}
        {props?.isOwner == true &&
        +props?.authData?.role ===
          APPLICATION_CONSTANTS.USER_ROLE_PROFESSIONAL ? (
          <ul>
            {props?.isOwner ? (
              <li>
                <h5>Professional Profile</h5>
              </li>
            ) : null}
            <li>
              <h5>Entrepreneur</h5>

              {editPopup ? (
                <h6>
                  {state?.is_entreprenuer ? (
                    state?.is_entreprenuer
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <IonSelect
                  interface="popover"
                  interfaceOptions={options}
                  placeholder="Select One"
                  name="is_entreprenuer"
                  value={state && state.is_entreprenuer}
                  onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              )}
            </li>
            <li>
              <h5> What best describes you?</h5>

              {editPopup ? (
                <h6>
                  {state?.description ? (
                    state?.description
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  autoFocus
                  placeholder={APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                  value={state?.description}
                  className="inputChanges"
                  name="description"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Title</h5>
              {editPopup ? (
                <h6>
                  {state?.title ? (
                    state?.title
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.TITLE}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.TITLE}
                  value={state?.title}
                  className="inputChanges"
                  name="title"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Bio</h5>
              {editPopup ? (
                <h6>
                  {state?.bio ? (
                    state?.bio
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BIO}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.BIO}
                  value={state?.bio}
                  className="inputChanges"
                  name="bio"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Industry</h5>
              {editPopup ? (
                <h6>
                  {state?.industry ? (
                    state?.industry
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.INDUSTRY}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <div>
                  <IonSelect
                    interface="popover"
                    interfaceOptions={options}
                    placeholder="Select One"
                    name="industry"
                    value={state?.industry}
                    onIonChange={(e) => handleChange(e)}
                    style={{ border: "1px solid #c7c7c7" }}
                  >
                    {props &&
                      props.industryData &&
                      props.industryData &&
                      props.industryData.data &&
                      props.industryData.data.map((data) => {
                        return (
                          <div>
                            <IonSelectOption value={data.industry_type}>
                              {data.industry_type}
                            </IonSelectOption>
                          </div>
                        );
                      })}
                  </IonSelect>
                </div>
              )}
            </li>
            {state?.industry === "Other" ? (
              <li>
                {editPopup ? null : (
                  <IonInput
                    type={"text"}
                    name="industry_other_detail"
                    className="form-control"
                    placeholder={"Please provide your industry."}
                    value={state?.industry_other_detail}
                    onIonChange={(event) => {
                      handleChange(event);
                    }}
                  ></IonInput>
                )}
              </li>
            ) : null}

            <li>
              <h5>Birthday</h5>
              {editPopup ? (
                <h6>
                  {state?.dob ? (
                    state?.dob
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BIO}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.DOB}
                  type="date"
                  className="inputChanges"
                  value={state?.dob}
                  name="dob"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
          </ul>
        ) : props?.isOwner == false &&
          +props?.profileDetail?.role ===
            APPLICATION_CONSTANTS.USER_ROLE_PROFESSIONAL ? (
          <ul>
            <li>
              <h5>Professional Profile</h5>
            </li>

            <li>
              <h5>Entrepreneur</h5>

              {editPopup ? (
                <h6>
                  {state?.is_entreprenuer ? (
                    state?.is_entreprenuer
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <></>
                // <IonSelect
                //   interface="popover"
                //   interfaceOptions={options}
                //   placeholder="Select One"
                //   name="is_entreprenuer"
                //   value={state && state.is_entreprenuer == 1 ?'Yes': "No"}
                //   onIonChange={(e) => handleChange(e)}
                //   style={{ border: "1px solid #c7c7c7" }}
                // >
                //   <IonSelectOption value={1}>Yes</IonSelectOption>
                //   <IonSelectOption value={0}>No</IonSelectOption>
                // </IonSelect>
              )}
            </li>
            <li>
              <h5> What best describes you?</h5>

              {editPopup ? (
                <h6>
                  {state?.description ? (
                    state?.description
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  autoFocus
                  placeholder={APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                  value={state?.description}
                  className="inputChanges"
                  name="description"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Title</h5>
              {editPopup ? (
                <h6>
                  {state?.title ? (
                    state?.title
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.TITLE}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.TITLE}
                  value={state?.title}
                  className="inputChanges"
                  name="title"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Bio</h5>
              {editPopup ? (
                <h6>
                  {state?.bio ? (
                    state?.bio
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BIO}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.BIO}
                  value={state?.bio}
                  className="inputChanges"
                  name="bio"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Industry</h5>
              {editPopup ? (
                <h6>
                  {state?.industry ? (
                    state?.industry
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.INDUSTRY}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <div>
                  <IonSelect
                    interface="popover"
                    interfaceOptions={options}
                    placeholder="Select One"
                    name="industry"
                    value={state?.industry}
                    onIonChange={(e) => handleChange(e)}
                    style={{ border: "1px solid #c7c7c7" }}
                  >
                    {props &&
                      props.industryData &&
                      props.industryData &&
                      props.industryData.data &&
                      props.industryData.data.map((data) => {
                        return (
                          <div>
                            <IonSelectOption value={data.industry_type}>
                              {data.industry_type}
                            </IonSelectOption>
                          </div>
                        );
                      })}
                  </IonSelect>
                </div>
              )}
            </li>
            <li>
              <h5>Birthday</h5>
              {editPopup ? (
                <h6>
                  {state?.dob ? (
                    state?.dob
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BIO}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.DOB}
                  type="date"
                  className="inputChanges"
                  value={state?.dob}
                  name="dob"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
          </ul>
        ) : props?.isOwner == true &&
          +props?.authData?.role === APPLICATION_CONSTANTS.USER_ROLE_STUDENT ? (
          <ul>
            {props?.isOwner ? (
              <li>
                <h5>Student Profile</h5>
              </li>
            ) : null}
            <li>
              <h5>Entrepreneur</h5>

              {editPopup ? (
                <h6>
                  {state?.is_entreprenuer ? (
                    state?.is_entreprenuer
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <IonSelect
                  interface="popover"
                  interfaceOptions={options}
                  placeholder="Select One"
                  name="is_entreprenuer"
                  value={state && state.is_entreprenuer}
                  onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              )}
            </li>
            <li>
              <h5> What best describes you?</h5>
              {editPopup ? (
                <h6>
                  {state?.description ? (
                    state?.description
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  autoFocus
                  name="description"
                  onChange={(e) => handleChange(e)}
                  placeholder={APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                  className="inputChanges"
                  value={state?.description}
                />
              )}
            </li>
            <li>
              <h5>Currently Enrolled</h5>
              {editPopup ? (
                <h6>
                  {state?.currently_enrld ? (
                    state?.currently_enrld
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.CURRENTLY_INVOLVED}
                    </span>
                  )}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.CURRENTLY_INVOLVED}
                  className="inputChanges"
                  value={state?.currently_enrld}
                  name="currently_enrld"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Area Of Study</h5>
              {editPopup ? (
                <h6>
                  {state?.industry ? (
                    state?.industry
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.AREA_OF_STUDY}
                    </span>
                  )}
                </h6>
              ) : (
                <IonSelect
                  interface="popover"
                  interfaceOptions={options}
                  placeholder="Select One"
                  name="industry"
                  value={state?.industry}
                  onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  {props &&
                    props.industryData &&
                    props.industryData &&
                    props.industryData.data &&
                    props.industryData.data.map((data) => {
                      return (
                        <div>
                          <IonSelectOption value={data.industry_type}>
                            {data.industry_type}
                          </IonSelectOption>
                        </div>
                      );
                    })}
                </IonSelect>
              )}
            </li>

            {state?.industry === "Other" ? (
              <li>
                {editPopup ? null : (
                  <IonInput
                    type={"text"}
                    name="industry_other_detail"
                    className="form-control"
                    placeholder={"Please provide your area of study"}
                    value={state?.industry_other_detail}
                    onIonChange={(event) => {
                      handleChange(event);
                    }}
                  ></IonInput>
                )}
              </li>
            ) : null}
            <li>
              <h5>School City/State</h5>
              {editPopup ? (
                <h6>
                  {state?.school_city ? (
                    state?.school_city
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.SCHOOL_CITY}
                    </span>
                  )}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.SCHOOL_CITY}
                  className="inputChanges"
                  value={state?.school_city}
                  name="school_city"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Birthday</h5>
              {editPopup ? (
                <h6>
                  {state?.dob ? (
                    state?.dob
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.DOB}
                    </span>
                  )}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.DOB}
                  className="inputChanges"
                  type="date"
                  value={state?.dob}
                  name="dob"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
          </ul>
        ) : props?.isOwner == false &&
          +props?.profileDetail?.role ===
            APPLICATION_CONSTANTS.USER_ROLE_STUDENT ? (
          <ul>
            {props?.isOwner ? (
              <li>
                <h5>Student Profile</h5>
              </li>
            ) : null}
            <li>
              <h5>Entrepreneur</h5>

              {editPopup ? (
                <h6>
                  {state?.is_entreprenuer ? (
                    state?.is_entreprenuer
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <IonSelect
                  interface="popover"
                  interfaceOptions={options}
                  placeholder="Select One"
                  name="is_entreprenuer"
                  value={state && state.is_entreprenuer}
                  onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              )}
            </li>
            <li>
              <h5> What best describes you?</h5>
              {editPopup ? (
                <h6>
                  {state?.description ? (
                    state?.description
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                    </span>
                  )}{" "}
                </h6>
              ) : (
                <input
                  autoFocus
                  name="description"
                  onChange={(e) => handleChange(e)}
                  placeholder={APPLICATION_CONSTANTS.BEST_DESCRIBES_YOU}
                  className="inputChanges"
                  value={state?.description}
                />
              )}
            </li>
            <li>
              <h5>Currently Enrolled</h5>
              {editPopup ? (
                <h6>
                  {state?.currently_enrld ? (
                    state?.currently_enrld
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.CURRENTLY_INVOLVED}
                    </span>
                  )}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.CURRENTLY_INVOLVED}
                  className="inputChanges"
                  value={state?.currently_enrld}
                  name="currently_enrld"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Area Of Study</h5>
              {editPopup ? (
                <h6>
                  {state?.industry ? (
                    state?.industry
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.AREA_OF_STUDY}
                    </span>
                  )}
                </h6>
              ) : (
                <IonSelect
                  interface="popover"
                  interfaceOptions={options}
                  placeholder="Select One"
                  name="industry"
                  value={state?.industry}
                  onIonChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  {props &&
                    props.industryData &&
                    props.industryData &&
                    props.industryData.data &&
                    props.industryData.data.map((data) => {
                      return (
                        <div>
                          <IonSelectOption value={data.industry_type}>
                            {data.industry_type}
                          </IonSelectOption>
                        </div>
                      );
                    })}
                </IonSelect>
                // <input
                //   placeholder={APPLICATION_CONSTANTS.AREA_OF_STUDY}
                //   className="inputChanges"
                //   value={state?.area_of_study}
                //   name="area_of_study"
                //   onChange={(e) => handleChange(e)}
                // />
              )}
            </li>
            <li>
              <h5>School City/State</h5>
              {editPopup ? (
                <h6>
                  {state?.school_city ? (
                    state?.school_city
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.SCHOOL_CITY}
                    </span>
                  )}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.SCHOOL_CITY}
                  className="inputChanges"
                  value={state?.school_city}
                  name="school_city"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
            <li>
              <h5>Birthday</h5>
              {editPopup ? (
                <h6>
                  {state?.dob ? (
                    state?.dob
                  ) : (
                    <span>
                      <IonIcon icon={addCircleOutline}></IonIcon>
                      {APPLICATION_CONSTANTS.DOB}
                    </span>
                  )}
                </h6>
              ) : (
                <input
                  placeholder={APPLICATION_CONSTANTS.DOB}
                  className="inputChanges"
                  type="date"
                  value={state?.dob}
                  name="dob"
                  onChange={(e) => handleChange(e)}
                />
              )}
            </li>
          </ul>
        ) : null}

        <div className="ellipsis-menu share-update-card__ellipsis-menu">
          <div className="collapsible-dropdown">
            {editPopup ? (
              props.isOwner ? (
                <button className="collapsible-dropdown__button ellipsis-menu__trigger">
                  <IonIcon
                    onClick={() => {
                      setEditPopup(false);
                    }}
                    style={{ color: "#314A8E" }}
                    icon={createOutline}
                  ></IonIcon>
                </button>
              ) : null
            ) : (
              <div>
                {" "}
                <button className="collapsible-dropdown__button ellipsis-menu__trigger">
                  <IonIcon
                    onClick={() => {
                      updateProfileDetails();
                    }}
                    style={{ color: "green" }}
                    icon={checkmarkCircleOutline}
                  ></IonIcon>
                </button>{" "}
                <button className="collapsible-dropdown__button ellipsis-menu__trigger">
                  <IonIcon
                    style={{ color: "red" }}
                    onClick={() => {
                      setEditPopup(true);
                    }}
                    icon={closeCircleOutline}
                  ></IonIcon>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="share-details-card">
        <ul>
          <li>
            <h5> GuestBook</h5>
            <h6 style={{ textAlign: "center", padding: 30 }}>
              {" "}
              GuestBook is empty
            </h6>
          </li>
        </ul>
      </div> */}
    </div>
  );
};
export default React.memo(UserInfo);
