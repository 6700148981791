import React, { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
import { menuController } from "@ionic/core";

import ReactGa from "react-ga";
ReactGa.initialize("UA-188032881-2");
const ThankYouPage = (props) => {
  useEffect(() => {
    // menuController.enable(false);
    ReactGa.pageview(window.location.pathname + window.location.search);
    window.gtag("event", "conversion", {
      send_to: "AW-420170528/OExACM-rzKUCEKCWrcgB",
    });
  }, []);
  return (
    <IonContent>
      <div className="signup">
        <div class="jumbotron text-center">
          <h1 class="display-3">Thank You!</h1>
          <p class="lead">
            <strong>Please check your email</strong> for further instructions on
            how to complete your account setup.
          </p>
          <hr />
          <p>
            Having trouble? <Link to={ROUTES.ContactUs}>Contact us</Link>
          </p>
          <p class="lead">
            <Link
              class="btn btn-primary btn-sm"
              // href="https://bootstrapcreative.com/"
              to={ROUTES.Home}
              role="button"
            >
              Continue to homepage
            </Link>
          </p>
        </div>
      </div>
    </IonContent>
  );
};

export default ThankYouPage;
