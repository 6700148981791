import {
    IonContent
  } from "@ionic/react";
  import React, { useEffect } from "react";
  import "./Student.css";
  import Footer from "../../components/Footer/Footer";
  import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../Services";
  const Student = () => {
    
    return (
      <IonContent>
        <section className="StudentTop">
          <div className="table">
            <div className="table-cell">
              {/* <img
                className="logo"
                src={require("../../images/StudentImages/wl.png")}
              /> */}
              <h1 className="headingMain">
                Students
              </h1>
            </div>
          </div>
        </section>
  
        <section className="StudentSection2 container">
          <div className="col-md-6 col-sm-12">
   <p className="TopPara">         
<b>StudentProCONNECT<sup >®</sup></b> &nbsp; connects you to top industry professionals using a user-friendly & easily accessible tool. Network and gain insight into majors & career options.
     </p>      <ol className="gradient-list">
         <li>Obtain career information that you need to help you succeed
             </li>
             <li>
             Undecided on a major? Ask the right questions to establish your path
             </li>
             <li>
             Create lasting relationships with professionals in your industry
             </li>
             <li>
             Head into the workforce with a network of contacts & resources
             </li>
         </ol>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              alt="image"
              style={{ maxWidth: "100%" }}
              src={require("../../images/StudentImages/Students.png")}
            />
          </div>
          
        </section>
        {localStorage.getItem("token") ? null : (
							<div className="loginSignup" style={{textAlign:'center'}}>
								<span style={{ margin: "2px 10px" }}>
									<Link to={ROUTES.Login}>
										<Button
											style={{
												borderRadius: "2rem",
												padding: "5px 35px",
												background: "#ffffff",
												borderColor: "#092C05",
												color: "black",
                        width:'250px',
                        marginRight:'20px'
											}}
										>
											Login{" "}
										</Button>
									</Link>{" "}
									<Link to={ROUTES.SignUp}>
										<Button
											style={{
												borderRadius: "2rem",
												padding: "5px 35px",
												background: "#ffffff",
												borderColor: "#092C05",
												color: "black",
                        width:'250px'
											}}
										>
											Sign Up{" "}
										</Button>
									</Link>{" "}
								</span>
							</div>
						)}
        <Footer></Footer>
      </IonContent>
    );
  };
  
  export default Student;
  