import React from 'react';
// import 'react-toastify/dist/ReactToastify.css';
import './Welcome.css';

export default class WelocomeCard extends React.Component{
    render(){
        return(
            <div className="viewWelcomeBoard">
                <img
                className="avatarWelcome"
                // src={this.props.currentUserPhoto}
                alt=""
                />
                <span className="textTitleWelcome">{`Chat`}</span>
                <span className="textDesciptionWelcome">
                    
                </span>
            </div>
        )
    }
}