import {
	IonContent,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNote,
} from "@ionic/react";
// import { useLocation } from 'react-router-dom';
import { APPLICATION_CONSTANTS, ROUTES } from "../Services/index";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
	logOut,
	cardOutline,
	homeOutline,
	homeSharp,
	person,
	peopleOutline,
	peopleSharp,
	bookOutline,
	bookSharp,
	videocamOutline,
	videocamSharp,
	calendarOutline,
	calendarSharp,
	heartOutline,
	heartSharp,
	clipboardOutline,
	clipboardSharp,
	logoPlaystation,
	ribbonOutline,
	personCircleOutline,
	schoolOutline,
	mailOpenOutline,
	newspaperOutline,
	logOutOutline,
	personOutline,
	personAddOutline,
	chatbox,
	chatboxOutline,
	logInOutline,
	chatbubbleOutline,
	people,
	nuclear,
	newspaper,
} from "ionicons/icons";

import "./Menu.css";

const Menu = (props) => {
	//<---- hide the side menu --->
	let Webroutes = [
		ROUTES.Home,
		ROUTES.About,
		ROUTES.ContactUs,
		ROUTES.Student,
		ROUTES.Industry_Professional,
		ROUTES.Entrepreneurs,
		ROUTES.Podcast,
		ROUTES.Podcast,
		ROUTES.Login,
		ROUTES.SignUp,
		ROUTES.TERMS_AND_CONDITION,
		ROUTES.PRIVATE_POLICY,
		ROUTES.ForgetPassword,
		ROUTES.ResetPassword,
		ROUTES.ThankYouPage,
	];

	const location = useLocation();
	let route = location.pathname;

	const reloadIframe = () => {
		if (document.getElementById("discussion-forum-frame")) {
			var iframe = document.getElementById("discussion-forum-frame");
			iframe.src = iframe.src;
		}
		props.history.push("/dsf");
	};
	return (
		<IonMenu
			contentId="main"
			type="overlay"
			className={
				Webroutes.includes(route) ? "dashion-menu" : "dashion-menu-app"
			}
		>
			<IonContent>
				<div className="sidebar-head" style={{ height: 57 }}>
					<div className="sidebar-user"></div>
				</div>

				<IonList id="inbox-list" class="menulist">
					<IonMenuToggle autoHide={false}>
						<IonItem lines="none" detail={false}>
							{route && Webroutes.includes(route) ? (
								<div className="sidebar-middiv" tabindex="0">
									{/* <div className="sidebar-middiv" tabindex="0"> */}
									<Link to={ROUTES.Home}>
										<IonLabel>
											{" "}
											<IonIcon icon={homeOutline}></IonIcon>Home
										</IonLabel>
									</Link>
									{/* </div>{" "} */}
									<Link to={ROUTES.Student}>
										<IonLabel>
											<IonIcon icon={schoolOutline}></IonIcon>Students
										</IonLabel>
									</Link>{" "}
									<Link to={ROUTES.Industry_Professional}>
										<IonLabel>
											<IonIcon icon={ribbonOutline}></IonIcon>Industry
											Professionals
										</IonLabel>
									</Link>{" "}
									<Link to={ROUTES.Entrepreneurs}>
										<IonLabel>
											<IonIcon icon={people}></IonIcon>Entrepreneurs
										</IonLabel>
									</Link>{" "}
									<Link to={ROUTES.Podcast}>
										<IonLabel>
											<IonIcon icon={nuclear}></IonIcon>PodCast
										</IonLabel>
									</Link>{" "}
									<Link to={ROUTES.ContactUs}>
										{" "}
										<IonLabel>
											<IonIcon icon={personOutline}></IonIcon> Contact Us
										</IonLabel>{" "}
									</Link>{" "}
									{localStorage.getItem("token") ? (
										<li>
											<Link to={ROUTES.Profile}>
												<IonLabel>
													<IonIcon icon={newspaperOutline}></IonIcon> Account
												</IonLabel>{" "}
											</Link>{" "}
										</li>
									) : (
										<li>
											<Link to={ROUTES.Login}>
												<IonLabel>
													<IonIcon icon={logInOutline}></IonIcon> Login
												</IonLabel>{" "}
											</Link>
											<Link to={ROUTES.SignUp}>
												<IonLabel>
													<IonIcon icon={logOutOutline}></IonIcon> Sign Up
												</IonLabel>{" "}
											</Link>
										</li>
									)}
								</div>
							) : (
								<div className="sidebar-middiv" tabindex="0">
									<Link to={ROUTES.Home}>
										<IonLabel>
											{" "}
											<IonIcon icon={homeOutline}></IonIcon>Home
										</IonLabel>
									</Link>
									<Link to={ROUTES.NEWS_FEED}>
										<IonLabel>
											{" "}
											<IonIcon icon={newspaper}></IonIcon>Feed
										</IonLabel>
									</Link>
									<Link to={ROUTES.Profile}>
										<IonLabel>
											{" "}
											<IonIcon icon={personOutline}></IonIcon>Profile
										</IonLabel>
									</Link>
									<Link to={ROUTES.Connect}>
										<IonLabel>
											{" "}
											<IonIcon icon={personAddOutline}></IonIcon>Connect
										</IonLabel>
									</Link>

									<Link to={ROUTES.Chat}>
										<IonLabel>
											{" "}
											<IonIcon icon={chatboxOutline}></IonIcon>Chat
										</IonLabel>
									</Link>
									<a onClick={reloadIframe}>
										<IonLabel>
											{" "}
											<IonIcon icon={chatbubbleOutline}></IonIcon>Forum
										</IonLabel>
									</a>
									<a
										onClick={async () => {
											await localStorage.removeItem(
												APPLICATION_CONSTANTS.USER_DATA
											);
											await localStorage.removeItem(
												APPLICATION_CONSTANTS.TOKEN
											);
											await props.history.push("/");
										}}
									>
										<IonLabel>
											<IonIcon icon={logOutOutline}></IonIcon>Logout
										</IonLabel>
									</a>
								</div>
							)}
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default withRouter(Menu);
