function isBlank(str) {
	return !str || /^\s*$/.test(str);
}

export const ValidateLoginForm = (formData) => {
	const { email, role, password, confirmPassword } = formData || {};
	let error = { email: [], password: [] };
	let isFormValid = true;

	if (isBlank(email) === true) {
		isFormValid = false;
		error.email = ["This field is required."];
	} else if (
		email.trim().length > 0 &&
		/^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
			formData.email
		) === false
	) {
		isFormValid = false;
		error.email = ["Email must be a valid email address."];
	}

	if (isBlank(password) === true) {
		isFormValid = false;
		error.password = ["This field is required."];
	} else if (
		password?.trim().length > 0 &&
		(password?.trim().length < 6 || password?.trim().length > 17)
	) {
		isFormValid = false;
		error.password = ["Password length must be between 6 to 17 characters."];
	}

	return { isFormValid, error };
};
