import Api from "../../Api";
import { handleError } from "./commonAction";
import {
	ENDPOINTS,
	ACTION_TYPE,
	ROUTES,
	APPLICATION_CONSTANTS,
} from "../../Services/index";

export const getUserDetails = (body) => async (dispatch) => {
	try {
		const res = await Api.get(ENDPOINTS.GET_PROFILE_DETAILS + body.id);
		await dispatch({
			type: ACTION_TYPE.USER_PROFILE_DETAILS,
			payload: { data: res.data },
		});
		return res?.data?.data;
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const DeletePost = (postId) => async (dispatch) => {
	try {
		const res = await Api.patch(ENDPOINTS.DELETE_POSTS + postId);
		await dispatch({
			type: ACTION_TYPE.DELETE_POST,
			payload: "deleted",
		});
		// return res?.data?.data;
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const updateProfileDetails = (body, user) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING_STARTED" });
		const res = await Api.post(
			ENDPOINTS.UPDATE_PROFILE_DETAILS + user.id + "/" + user.role,
			body
		);

		dispatch({
			type: ACTION_TYPE.UPDATE_USER_PROFILE,
			payload: { data: res.data },
		});
		dispatch({ type: "LOADING_COMPLETED" });
	} catch (error) {
		dispatch({ type: "LOADING_FAILURE" });
		dispatch(handleError(error));
	}
};

export const updateCoverImage = (body, id) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING_STARTED" });
		const res = await Api.post(ENDPOINTS.CHANGE_COVER_IMAGE + id, body);
		console.log("RESSSSSSSSSSSSSS", res);
		dispatch({
			type: ACTION_TYPE.UPLOAD_COVER_IMAGE,
			payload: { data: res.data },
		});
		dispatch({ type: "LOADING_COMPLETED" });
	} catch (error) {
		dispatch({ type: "LOADING_FAILURE" });
		dispatch(handleError(error));
	}
};

export const updateProfileImage = (body, id) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING_STARTED" });
		const res = await Api.post(ENDPOINTS.CHANGE_PROFILE_IMAGE + id, body);
		console.log("RESSSSSSSSSSSSSS", res);
		dispatch({
			type: ACTION_TYPE.UPLOAD_PROFILE_IMAGE,
			payload: { data: res.data },
		});
		dispatch({ type: "LOADING_COMPLETED" });
	} catch (error) {
		dispatch({ type: "LOADING_FAILURE" });
		dispatch(handleError(error));
	}
};

export const uploadPost = (body) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING_STARTED" });
		const res = await Api.post(ENDPOINTS.UPLOAD_POST, body);
		console.log("RESSSSSSSSSSSSSS", res);
		dispatch({
			type: ACTION_TYPE.UPLOAD_POST,
			payload: { data: res.data },
		});
		dispatch({ type: "LOADING_COMPLETED" });
	} catch (error) {
		dispatch({ type: "LOADING_FAILURE" });
		dispatch(handleError(error));
	}
};

export const updateAdminDetails = (params) => async (dispatch) => {
	console.log("updateAdminDetails", params);
	return new Promise((resolve, reject) => {
		dispatch({ type: "LOADING_STARTED" });
		Api.post(ENDPOINTS.ADMIN_PROFILE_DETAILS, params)
			.then((res) => {
				console.log("profile ", res);

				dispatch({
					type: ACTION_TYPE.ADMIN_PROFILE_DETAILS,
					payload: res.data.data,
				});
				resolve(res?.data);
				dispatch({ type: "LOADING_COMPLETED" });
			})
			.catch((err) => {
				dispatch({ type: "LOADING_FAILURE" });
				reject(err);
				// dispatch(handleError(error));
			});
	});
};
export const retainAdminDetails = () => async (dispatch) => {
	console.log("retainAdminDetails");
	return new Promise((resolve, reject) => {
		dispatch({ type: "LOADING_STARTED" });
		Api.post(ENDPOINTS.RETAIN_ADMIN_DETAILS)
			.then((res) => {
				console.log("admin profile ", res);

				dispatch({
					type: ACTION_TYPE.RETAIN_ADMIN_DETAILS,
					payload: res.data,
				});
				resolve(res?.data);
				dispatch({ type: "LOADING_COMPLETED" });
			})
			.catch((err) => {
				dispatch({ type: "LOADING_FAILURE" });
				reject(err);
				// dispatch(handleError(error));
			});
	});
};
