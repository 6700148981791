import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
   apiKey: "AIzaSyC7EsSZ9-grsIR7s-7OyZgSdeip4vCKmmY",
   authDomain: "pro-users-chat.firebaseapp.com",
   projectId: "pro-users-chat",
   storageBucket: "pro-users-chat.appspot.com",
   messagingSenderId: "320595989188",
   appId: "1:320595989188:web:8f9bab2c898a809316af91"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// export const auth = firebase.auth();
export default firebase;

