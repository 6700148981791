export const ACTION_TYPE = {
	LOGIN: "LOGIN",
	SIGNUP: "SIGNUP",
	VERIFY_EMAIL: "VERIFY_EMAIL",
	TABS: "TABS",
	USER_POSTS: "USER_POSTS",
	DELETE_POST: "DELETE_POST",
	FORGET_PASSWORD: "FORGET_PASSWORD",
	RESET_PASSWORD: "RESET_PASSWORD",
	CONTACT_US: "CONTACT_US",
	//Profile Actions
	USER_PROFILE_DETAILS: "USER_PROFILE_DETAILS",
	ADMIN_PROFILE_DETAILS: "ADMIN_PROFILE_DETAILS",
	RETAIN_ADMIN_DETAILS: "RETAIN_ADMIN_DETAILS",
	UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
	CLEAR_PROFILE_STATE: "CLEAR_PROFILE_STATE",
	UPLOAD_COVER_IMAGE: "UPLOAD_COVER_IMAGE",
	UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",

	UPLOAD_POST: "UPLOAD_POST",

	//Connection API

	GET_MY_CONNECTION: "GET_MY_CONNECTION",
	SEARCH_CONNECTIONS: "SEARCH_CONNECTIONS",
	ADD_CONNECTION: "ADD_CONNECTION",

	SEARCH_TEXT: "SEARCH_TEXT",
	CLEAR_SEARCH_LIST: "CLEAR_SEARCH_LIST",
	GET_INDUSTRY: "GET_INDUSTRY",
	GET_NEWSFEED: "NEWSFEED",
	SHAREPOST: "SHAREPOST",
	LIKEPOST: "LIKEPOST",
	DISLIKEPOST: "DISLIKEPOST",
	POSTCOMMENT: "POSTCOMMENT",

	//Dynamic Pages API

	GET_DYNAMIC_PAGE_CONTENT: "GET_DYNAMIC_PAGE_CONTENT",
	CLEAR_DYNAMIC_PAGE_CONTENT: "CLEAR_DYNAMIC_PAGE_CONTENT",
};
