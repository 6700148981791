import React from 'react';
import {
    IonContent
  } from "@ionic/react";
  import Footer from "../../components/Footer/Footer";
const AboutUs = () => {
    return (<IonContent>
           <section className="StudentTop">
          <div className="table">
            <div className="table-cell">
              
              <h1 className="headingMain">
                About Us
              </h1>
            </div>
          </div>
        </section>
        <Footer></Footer>
        </IonContent>);
}
 
export default AboutUs;