


import Api from "../../Api";
import { handleError } from "./commonAction";
import {
  ENDPOINTS,
  ACTION_TYPE,
  ROUTES,
  APPLICATION_CONSTANTS,
} from "../../Services/index";
  export const GetIndustryAction = () => async (dispatch) => {
    const res = await Api.get(ENDPOINTS.GET_INDUSTRY);
    try {
      await dispatch({
        type: ACTION_TYPE.GET_INDUSTRY,
        payload: res.data,
      });    
      // dispatch({ type: "LOADING_COMPLETED" });
      // return res.data;
    } catch (error) {
      // dispatch({ type: "LOADING_FAILURE" });
      // dispatch(handleError(error));
      
    }
  };
  