import { ACTION_TYPE ,APPLICATION_CONSTANTS} from "../../Services/index";
export const initialState = {
    TabsData:1,
  };
  
  export const TabsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE.TABS:
            return {
              ...state,
              TabsData: action.payload,
            };
      default:
        return state;
    }
  };
  