import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
import { APPLICATION_CONSTANTS } from "../../Services/Strings/index";

export const GetNewsFeed = () => async (dispatch) => {
	let userData = JSON.parse(
		localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
	);
	const res = await Api.post(ENDPOINTS.GET_NEWSFEED, {
		userId: userData.id,
	});
	console.log("RRRRR", res?.data?.data);
	try {
		await dispatch({
			type: ACTION_TYPE.GET_NEWSFEED,
			payload: res.data,
		});
	} catch (error) {}
};
