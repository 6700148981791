import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
	likes: [],
};

export const dislikeReducer = (state = initialState, action) => {
	console.log("likes", action.type);
	console.log("likes data", action.payload);

	switch (action.type) {
		case ACTION_TYPE.DISLIKEPOST:
			return {
				...state,
				likes: action.payload?.data,
			};

		default:
			return state;
	}
};
