import React from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ScrollToTop from "./Utils/ScrollToTop";
import { useDispatch } from "react-redux";
import { AuthActions } from "./ReduxStore/Actions/index";
import Menu from "./components/Menu";
// import {
//   Plugins
// } from '@capacitor/core';

import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";

import Routing from "./AuthRouter/PublicRoutes";
import { APPLICATION_CONSTANTS } from "./Services";
// const { Keyboard } = Plugins;


const App = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {

    (async () => {
      // Keyboard.setAccessoryBarVisible({isVisible: true});
      if (
        localStorage.getItem(APPLICATION_CONSTANTS.TOKEN) &&
        localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
      ) {        
        await dispatch(AuthActions.matchToken());
        await dispatch({ type: "CLEAR_LOGIN" });
      }

    })();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <ScrollToTop>
              <Routing />
            </ScrollToTop>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
