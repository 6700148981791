import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import "./ContactUs.css";
import { useSelector, useDispatch } from "react-redux";
import { ValidateContactForm } from "./Validation";
import { AuthActions } from "../../ReduxStore/Actions";

import { Toast } from "../../Utils/Toast";

const options = {
  cssClass: "custom-interface-popover",
};

const Signup = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState();
  const [errorData, setError] = React.useState();
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const loading = useSelector((state) => state.commonReducer.loading);
  const authData = useSelector((state) => state.authReducer);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  async function onSubmitForm(event) {
    event.preventDefault();
    const result = await ValidateContactForm(state);

    if (result?.isFormValid) {
      console.log("AAA", result, state);
      await dispatch(AuthActions.contactUs(state));
    } else {
      setError(result?.error);
      console.log("Sign up form submitted", JSON.stringify(result));
    }
  }
  return (
    <IonContent>
      {(errorStatus === false || errorStatus === true) && errorMessage ? (
        <Toast
          isOpen={errorStatus === false ? true : false}
          message={errorMessage}
          isSuccess={errorStatus}
          isClearSuccess={true}
          isClearError={true}
        />
      ) : authData?.status ? (
        <Toast
          isOpen={authData?.status}
          message={authData?.message}
          isSuccess={authData?.status}
          isClearSuccess={true}
          isClearError={true}
          duration={3000}
        />
      ) : null}
      <div className="signup">
        <div className="login-inner">
          <div className="login-logo">
            {/* <IonImg src={loginlogo} alt="logo"></IonImg> */}
          </div>
          {/* <div className="login-tagline">
                <h3>Inspired by Coaches</h3>
              </div> */}

          <div className="login-head">
            <h2>Contact Us </h2>
            <div className="errormsg">{/* <p>{props.errorMsg}</p> */}</div>
          </div>

          <div className="login-body">
            <form>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Name</IonLabel>
                      <IonInput
                        type="text"
                        name="fullName"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.fullName[0] ? (
                        <p>{errorData?.fullName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Email</IonLabel>
                      <IonInput
                        type="email"
                        name="email"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonInput>
                      {errorData?.email[0] ? (
                        <p>{errorData?.email[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Message</IonLabel>
                      <IonTextarea
                        type="text"
                        name="message"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange(event);
                        }}
                      ></IonTextarea>
                      {errorData?.message[0] ? (
                        <p>{errorData?.message[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>

                {/* <IonRow>
                      <IonCol size="12" size-md="12">
                        <div className="form-group">
                          <IonLabel>Logo</IonLabel>
                          <input
                            type="file"
                            accept="image/*"
                            name="teamLogo"
                            className="form-control"
                            onChange={(event)=>{handleChange("teamLogo",event)}}
                          ></input>
                          {errorData?.confirmPassword[0] ? (
                      <p>{errorData?.confirmPassword[0]}</p>
                    ) : null}
                        </div>
                      </IonCol>
                    </IonRow> */}
              </IonGrid>
              {/* <div className="forgot-password">
                    <Link to={"/signup"}>Don't have Account? SignUp</Link>
                    <a className="forgot-text">Forgot Password?</a>
                    <Link to="/login" className="forgot-text">
                      {" "}
                      Have Account? Login
                    </Link>
                  </div> */}
              <div className="button-login">
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Send{" "}
                  {(() => {
                    if (loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                  {/* {(() => {
                        if (props.loading) {
                          return <IonSpinner name="crescent" className="spinner-loader" />
                        }
                      })()} */}
                </IonButton>
              </div>

              {/* <div className="button-login"> */}
              {/* {isWeb === true ? <div className="login-social-heading">
                    <p>Or Sign Up with</p>
                  </div> : null} */}

              {/* </div> */}
              <div className="private-policy"></div>
            </form>
            {/* <PrivatePolicy showPrivatePolicy={privatePolicy} setPrivatePolicy={() => { setPrivatePolicy(false) }} /> */}
            {/* <TNC showPrivatePolicy={tnc} setPrivatePolicy={() => { setTnc(false) }} /> */}
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </IonContent>
  );
};

export default Signup;
