import React from "react";
import { Route } from "react-router-dom";
import { IonPage } from "@ionic/react";
import Home from "./Home/Home";
import Student from "./Student/Student";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./WebsiteModule.css";

import { ROUTES } from "../Services/index";

import AboutUs from "./AboutUs/AboutUs";
import Professionals from "./Professionals/Professionals";
import ContactUs from "./ContactUs/ContactUs";
import DynamicPages from "./DynamicPages/Dynamic";
import Entrepreneurs from "./Entrepreneurs/Entrepreneurs";
import Header from "../components/Header/Header";
import "./WebsiteModule.css";
import Podcast from "./Podcast/Podcast";

/* Theme variables */

const WebsiteModule = () => (
	<IonPage style={{ paddingTop: 55 }}>
		<Header />
		<Route path={ROUTES.Home} component={Home} exact />
		<Route path={ROUTES.About} component={AboutUs} exact />
		<Route path={ROUTES.ContactUs} component={ContactUs} exact />
		<Route path={ROUTES.Student} component={Student} exact />
		<Route path={ROUTES.Entrepreneurs} component={Entrepreneurs} exact />
		<Route path={ROUTES.Podcast} component={Podcast} exact />
		<Route
			path={ROUTES.Industry_Professional}
			component={Professionals}
			exact
		/>
		<Route path={ROUTES.DYNAMIC_PAGE} component={DynamicPages} exact />
	</IonPage>
);

export default WebsiteModule;
